import React, { memo, forwardRef, useCallback } from "react";
import { Alert } from "@mui/material";
import { SnackbarContent, useSnackbar } from "notistack";

// eslint-disable-next-line react/display-name

const MuiAlert = forwardRef(({
  message,
  muiVariant = "outlined",
  muiSeverity = "info"
}, forwardedRef) => {
  const { closeSnackbar } = useSnackbar();
  const bgColor = (muiVariant === "outlined") ? "background.paper" : "";

  const handleDismiss = useCallback(() => {
    closeSnackbar();
  }, [closeSnackbar]);

  return (
    <SnackbarContent
      ref={forwardedRef}
    >
      <Alert
        className="cursor-pointer"
        onClick={handleDismiss}
        variant={muiVariant}
        severity={muiSeverity}
        sx={{ bgcolor: bgColor }}
      >

        {message}
      </Alert>
    </SnackbarContent>
  );
});

export default memo(MuiAlert);
