import React, { useContext, useEffect, useState } from "react";

import { DndContext, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { SortableContext, arraySwap } from "@dnd-kit/sortable";
import { useMediaQuery } from "@mui/material";
import axios from "axios";
import sortBy from "lodash/sortBy";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import EventUserContext from "@event/EventUserContext";
import { renderButton, renderCancelButton } from "@shared/FormUtils";

import Widget from "./Widget";
import WidgetBoardContext from "./WidgetBoardContext";
import WidgetNewModal from "./WidgetNewModal";

const WidgetBoard = (props) => {
  const { metaFields, onUpdate, optimisticUpdateWidgets, selectedBoard, widgetBoard, widgetBoardValues } = props;
  const [editMode, setEditMode] = useState(false);
  const [widgetSettingsVisible, setWidgetSettingsVisible] = useState(false);
  const { apiRoot } = useContext(EventContext).values;
  const { user } = useContext(EventUserContext);
  const minWidth = useMediaQuery("(max-width:700px)");
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 16
      }
    })
  );

  useEffect(() => {
    setEditMode(false);
  }, [selectedBoard]);

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const isEditEnabled = () => {
    if (user.role === "basic" && selectedBoard !== "user") {
      return false;
    }
    return true;
  };

  const handleDragEnd = ({ active, over }) => {
    if (!over) {
      return;
    }
    if (active.id !== over.id) {
      // collect widget IDs in their current widget_position order
      const widgetIds = sortBy(widgetBoard.widgets, ["widget_position", "id"]).map((widget) => widget.id);

      // swap the positions of the dragged widget and the one it was dropped on
      const newWidgetIds = arraySwap(widgetIds, widgetIds.indexOf(active.id), widgetIds.indexOf(over.id));

      // post to service which will rewrite widget_positions into a new sequence
      axios({
        method: "POST",
        url: urljoin(apiRoot, "/dashboard/", `${widgetBoard.id}`, "/sort"),
        data: {
          order: newWidgetIds
        }
      })
        .then((result) => {
          // onUpdate();
          console.log(result.data);
        })
        .catch((error) => {
          console.log(error);
        });

      optimisticUpdateWidgets(active, over);
    }
  };

  const renderWidgets = () => {
    if (minWidth) {
      return <div className="text-sg-orange">Screen must be wider than 700px to display dashboard widgets.</div>;
    }

    if (widgetBoard.widgets.length === 0) {
      return (
        <div className="w-full pt-28 text-center italic">
          No widgets created for this board. Click &quot;Edit&quot; to enable Edit Mode and add widgets.
        </div>
      );
    }

    const sortedWidgets = sortBy(widgetBoard.widgets, ["widget_position", "id"]);
    return (
      <div className="grid grid-cols-1 gap-2 lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-4">
        <SortableContext items={sortedWidgets}>
          {sortedWidgets.map((widget) => (
            <Widget
              key={widget.id}
              onUpdate={onUpdate}
              widget={widget}
              values={widgetBoardValues[widget.id]}
              editMode={editMode}
              widgetSettingsVisible={widgetSettingsVisible}
            />
          ))}
        </SortableContext>
      </div>
    );
  };

  const renderControls = () => {
    if (!isEditEnabled()) {
      return <></>;
    }

    if (editMode) {
      return (
        <div className="mr-4 inline-block">
          <span className="mr-4">
            <WidgetNewModal onUpdate={onUpdate} widgetBoard={widgetBoard} />
          </span>
          {renderCancelButton("Exit Edit Mode", toggleEditMode)}
        </div>
      );
    }

    return <div className="mr-4 inline-block">{renderButton("Edit Dashboard", toggleEditMode)}</div>;
  };

  const renderDashboardText = () => {
    if (widgetBoard.default_board) {
      return (
        <span>
          {
            "This is the event dashboard, which provides at-a-glance visibility of your event and lets you monitor real-time data in a way that is easy to understand. The event dashboard is visible to all users of this site."
          }
        </span>
      );
    }

    return (
      <span>
        {
          "This is your personal dashboard, where you can add and customize widgets that are important to you. This dashboard is only visible to you."
        }
      </span>
    );
  };

  return (
    <WidgetBoardContext.Provider
      value={{
        ...metaFields,
        widgetBoard: widgetBoard,
        setWidgetSettingsVisible: setWidgetSettingsVisible
      }}
    >
      <div className="max-w-screen-xl pr-16 sm:pr-0">
        <div>
          <h1 className="text-2xl text-sg-orange">Welcome!</h1>
        </div>
        <div className="mb-4 flex">
          <div className="grow">{renderDashboardText()}</div>
          {minWidth ? (
            ""
          ) : (
            <div className="shrink-0 basis-[160px] text-right lg:basis-[400px]">
              <div>{renderControls()}</div>
            </div>
          )}
        </div>
        <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
          {renderWidgets()}
        </DndContext>
      </div>
    </WidgetBoardContext.Provider>
  );
};

export default WidgetBoard;
