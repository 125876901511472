import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { renderCancelButton } from "@shared/FormUtils";

const blankSurvey = {
  answer_1: "",
  answer_2: "",
  answer_3: "",
  answer_4: "",
  answer_5: "",
  answer_6: "",
  answer_7: "",
  answer_8: "",
  answer_9: "",
  answer_10: "",
  comments: "",
  data: {
    session_title: "",
    attendee_name: ""
  }
};

const EventSurveysView = ({
  modalVisible,
  resetModal,
  survey = blankSurvey
}) => {
  const [selectedSurvey, setSelectedSurvey] = useState(blankSurvey);
  Modal.setAppElement("#root");

  useEffect(() => {
    if (survey && survey.id) {
      setSelectedSurvey(survey);
    } else {
      setSelectedSurvey(blankSurvey);
    }
  }, [survey]);

  const renderField = (label, value) => {
    return (
      <div className="sg-mgmt-modal-view-field">
        <span className="sg-mgmt-modal-view-field-label">{label}</span>
        :&nbsp;
        {value}
      </div>
    );
  };

  const renderViewSurveyModal = () => {
    return (
      <div className="sg-mgmt-modal-frame">
        <div className="sg-mgmt-modal-title">Survey</div>
        <div className="sg-mgmt-modal-content">
          <div className="sg-mgmt-modal-view">
            <div className="sg-mgmt-modal-view-section">
              <h2>Details</h2>
              {renderField("Session", selectedSurvey.data.session_title)}
              {renderField("Attendee", selectedSurvey.data.attendee_name)}
            </div>
            <div className="sg-mgmt-modal-view-section">
              <h2>Survey Answers</h2>
              {renderField("Question #1", selectedSurvey.answer_1)}
              {renderField("Question #2", selectedSurvey.answer_2)}
              {renderField("Comments", selectedSurvey.comments)}
            </div>
            <div className="sg-mgmt-modal-view-section">
              {renderCancelButton("Done", resetModal)}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Modal
      className="sg-mgmt-modal"
      overlayClassName="sg-mgmt-modal-overlay"
      isOpen={modalVisible}
      onRequestClose={resetModal}
      contentLabel="Add Speaker"
    >
      {renderViewSurveyModal()}
    </Modal>
  );
};

export default EventSurveysView;
