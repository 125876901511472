import React, { useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import { isDeveloper } from "@shared/UserUtils";
import EventUserContext from "@event/EventUserContext";
import { renderCancelButton } from "@shared/FormUtils";

const blankSpeaker = {
  name_last: "",
  name_first: "",
  full_name: "",
  job_title: "",
  company: "",
  bio: "",
  email: "",
  phone: "",
  address_street_1: "",
  address_street_2: "",
  address_city: "",
  address_state: "",
  address_postcode: ""
};

const EventSpeakersView = ({
  modalVisible,
  resetModal,
  speaker = blankSpeaker
}) => {
  const { user } = useContext(EventUserContext);
  const [selectedSpeaker, setSelectedSpeaker] = useState(blankSpeaker);
  Modal.setAppElement("#root");

  useEffect(() => {
    if (speaker && speaker.id) {
      setSelectedSpeaker(speaker);
    } else {
      setSelectedSpeaker(blankSpeaker);
    }
  }, [speaker]);

  const renderField = (label, value) => {
    return (
      <div className="sg-mgmt-modal-view-field">
        <span className="sg-mgmt-modal-view-field-label">{label}</span>
        :&nbsp;
        {value}
      </div>
    );
  };

  const renderDeveloper = () => {
    if (isDeveloper(user)) {
      return (
        <div className="sg-mgmt-modal-view-section">
          <h2>Developer Details</h2>
          {renderField("GID", selectedSpeaker.gid)}
        </div>
      );
    }
    return <></>;
  };

  /* eslint-disable dot-notation */
  const internalExternal = () => {
    if (!selectedSpeaker.data || !selectedSpeaker.data["internal_external"]) {
      return "not yet set";
    }
    return (
      selectedSpeaker.data["internal_external"].charAt(0).toUpperCase() +
      selectedSpeaker.data["internal_external"].slice(1)
    );
  };
  /* eslint-enable dot-notation */

  const speakerPhoto = () => {
    if (selectedSpeaker.photo_url) {
      return (
        <img
          className="sg-mgmt-form-speaker-photo-container"
          src={selectedSpeaker.photo_url}
          alt="speaker"
        />
      );
    }
    return <></>;
  };

  const renderViewSpeakerModal = () => {
    return (
      <div className="sg-mgmt-modal-frame">
        <div className="sg-mgmt-modal-title">{`Speaker: ${selectedSpeaker.name_first} ${selectedSpeaker.name_last}`}</div>
        <div className="sg-mgmt-modal-content">
          <div className="sg-mgmt-modal-view">
            <div className="sg-mgmt-modal-view-section">
              <h2>Personal Details</h2>
              {renderField("First Name", selectedSpeaker.name_first)}
              {renderField("Last Name", selectedSpeaker.name_last)}
              {renderField("Company", selectedSpeaker.company)}
              {renderField("Job Title", selectedSpeaker.job_title)}
            </div>
            <div className="sg-mgmt-modal-view-section">
              <h2>Speaker Details</h2>
              {renderField("Bio", selectedSpeaker.bio)}
              {renderField("Internal/External", internalExternal())}
            </div>
            <div className="sg-mgmt-modal-view-section">
              <h2>Photo</h2>
              {speakerPhoto()}
            </div>
            <div className="sg-mgmt-modal-view-section">
              <h2>Contact Information</h2>
              {renderField(
                "Address (line 1)",
                selectedSpeaker.address_street_1
              )}
              {renderField(
                "Address (line 2)",
                selectedSpeaker.address_street_2
              )}
              {renderField("City", selectedSpeaker.address_city)}
              {renderField("State/Province", selectedSpeaker.address_state)}
              {renderField("ZIP/Postal Code", selectedSpeaker.address_postcode)}
              {renderField("Email", selectedSpeaker.email)}
              {renderField("Phone", selectedSpeaker.phone)}
            </div>
            {renderDeveloper()}
            <div className="sg-mgmt-modal-view-section">
              {renderCancelButton("Done", resetModal)}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Modal
      className="sg-mgmt-modal"
      overlayClassName="sg-mgmt-modal-overlay"
      isOpen={modalVisible}
      onRequestClose={resetModal}
      contentLabel="Add Speaker"
    >
      {renderViewSpeakerModal()}
    </Modal>
  );
};

export default EventSpeakersView;
