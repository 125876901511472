import React from "react";

import Stack from "@mui/material/Stack";

import { renderCancelButton, renderSubmitButton } from "@shared/FormUtils";

const WidgetSettingsButtons = ({
  isEdit = false,
  isSubmitDisabled,
  closeModal
}) => {
  
  const color = isEdit ? "primary" : "secondary";
  const submitText = isEdit ? "Save" : "Add Widget";

  return (
    <div className="mb-4 mt-4">
      <Stack direction="row" spacing={2}>
        {renderSubmitButton(submitText, isSubmitDisabled, { color: color })}
        {renderCancelButton("Cancel", closeModal, { color: color })}
      </Stack>
    </div>
  );
};

export default WidgetSettingsButtons;
