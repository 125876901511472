import React from "react";

import { FormControl, FormLabel, MenuItem, Select } from "@mui/material";
import { Controller } from "react-hook-form";
import MuiSelectClearable from "@shared/MuiSelectClearable";
const WidgetMetadataFieldSelect = (props) => {
  const { control, metadataFields } = props;

  return (
    <div className="mb-4 mt-4">
      <div className="mb-4 w-full">
        <FormControl fullWidth>
          <FormLabel>Select Custom Field</FormLabel>
          <Controller
            name="widget_config[metadata_field_id]"
            control={control}
            render={({ field: { value, onChange, ...field } }) => (
              <Select
                {...field}
                fullWidth
                onChange={onChange}
                value={value}
                variant="outlined"
                size="small"
                MenuProps={{ disableScrollLock: true }}
                endAdornment={(
                  <MuiSelectClearable value={value} onChange={onChange} />
                )}
              >
                {metadataFields.map((md) => (
                  <MenuItem key={`md-${md.id}`} value={md.id}>
                    {`${md.label} (${md.slug})`}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
      </div>
    </div>
  );
};

export default WidgetMetadataFieldSelect;
