import React, { useContext, useState } from "react";

import axios from "axios";
import { useConfirm } from "material-ui-confirm";
import urljoin from "url-join";

import WidgetContext from "@dashboard/WidgetContext";
import EventContext from "@event/EventContext";
import { alertError, alertHttpError, alertSuccess } from "@shared/Alerts";

import WidgetBoardContext from "./WidgetBoardContext";
import WidgetSettingsModal from "./WidgetSettingsModal";

const WidgetSettings = ({
  label = "Settings",
  settingsComponent,
}) => {
  const [visible, setVisible] = useState(false);
  const { setWidgetSettingsVisible } = useContext(WidgetBoardContext);
  const { apiRoot } = useContext(EventContext).values;
  const { onUpdate, widget } = useContext(WidgetContext);
  const confirm = useConfirm();

  const confirmDelete = () => {
    const msgEnding = widget?.widget_config?.header ? `the ${widget?.widget_config?.header} widget` : "this widget";
    confirm({
      title: "Confirm delete",
      description: `Are you sure you want to delete ${msgEnding}?`
    })
      .then(() => {
        performDelete();
      })
      .catch(err => {
        if (err) alertError(err);
      });
  };

  const performDelete = () => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      url: urljoin(apiRoot, "/widgets", `/${widget.id}`),
      method: "DELETE"
    })
      .then(response => {
        if (response.data.error === null) {
          onUpdate();
          alertSuccess("Widget deleted successfully");
        } else {
          alertError(response.data.error);
        }
      })
      .catch(error => {
        alertHttpError(error);
      });
  };

  const resetModal = () => {
    setVisible(false);
    setWidgetSettingsVisible(false);
  };

  const openModal = () => {
    setVisible(true);
    setWidgetSettingsVisible(true);
  };

  return (
    <div className="absolute right-0 top-0 mr-2 mt-2 hidden cursor-pointer bg-white px-3 py-2 group-hover:block">
      <div className="flex uppercase leading-none text-[13px] font-medium tracking-wide text-ui-7">
        <div className="border-r border-r-ui-7 pr-2" onClick={openModal}>
          Edit
        </div>
        <div className="pl-2" onClick={confirmDelete}>
          Remove
        </div>
        <WidgetSettingsModal component={settingsComponent} label={label} resetModal={resetModal} visible={visible} />
      </div>
    </div>
  );
};

export default WidgetSettings;
