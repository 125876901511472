import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import Modal from "react-modal";
import urljoin from "url-join";
import { alertHttpError } from "@shared/Alerts";
import Loading from "@shared/Loading";
import EventContext from "@event/EventContext";
import { renderCancelButton } from "@shared/FormUtils";

const CommunicationsEmailMailingListPreviewModal = props => {
  const { apiRoot } = useContext(EventContext).values;
  const { listId, modalClose, modalVisible } = props;
  const [list, setList] = useState({});
  const [search, setSearch] = useState("");
  const [entries, setEntries] = useState([]);
  const [fetched, setFetched] = useState(false);
  
  Modal.setAppElement("#root");

  useEffect(() => {
    const fetchMailingList = async () => {
      try {
        const result = await axios(
          urljoin(apiRoot, `/communications/lists/${listId}`)
        );
        setList(result.data.list);
        setEntries(result.data.list.email_mailing_list_entries);
        setFetched(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchMailingList();
  }, []);

  const renderEntryEmails = entry => {
    const renderList = [];
    renderList.push(
      <React.Fragment key={entry.email}>{entry.email}</React.Fragment>
    );
    if (entry.cc_list && entry.cc_list.length > 0) {
      renderList.push(
        <div
          className="sg-mgmt-table-entries-cc"
          key={`${entry.email}-cclist`}
        >
          cc: {entry.cc_list}
        </div>
      );
    }
    if (entry.bcc_list && entry.bcc_list.length > 0) {
      renderList.push(
        <div
          className="sg-mgmt-table-entries-cc"
          key={`${entry.email}-bcclist`}
        >
          bcc: {entry.bcc_list}
        </div>
      );
    }
    if (entry.custom_tag_1 && entry.custom_tag_1.length > 0) {
      renderList.push(
        <div
          className="sg-mgmt-table-entries-cc"
          key={`${entry.email}-merge1`}
        >
          *|CUSTOM1|*: {entry.custom_tag_1}
        </div>
      );
    }
    if (entry.custom_tag_2 && entry.custom_tag_2.length > 0) {
      renderList.push(
        <div
          className="sg-mgmt-table-entries-cc"
          key={`${entry.email}-merge2`}
        >
          *|CUSTOM2|*: {entry.custom_tag_2}
        </div>
      );
    }
    if (entry.custom_tag_3 && entry.custom_tag_3.length > 0) {
      renderList.push(
        <div
          className="sg-mgmt-table-entries-cc"
          key={`${entry.email}-merge3`}
        >
          *|CUSTOM3|*: {entry.custom_tag_3}
        </div>
      );
    }
    if (entry.custom_tag_4 && entry.custom_tag_4.length > 0) {
      renderList.push(
        <div
          className="sg-mgmt-table-entries-cc"
          key={`${entry.email}-merge4`}
        >
          *|CUSTOM4|*: {entry.custom_tag_4}
        </div>
      );
    }
    if (entry.custom_tag_5 && entry.custom_tag_5.length > 0) {
      renderList.push(
        <div
          className="sg-mgmt-table-entries-cc"
          key={`${entry.email}-merge5`}
        >
          *|CUSTOM5|*: {entry.custom_tag_5}
        </div>
      );
    }

    return renderList;
  };

  const filteredEntries = () => {
    return entries.filter(entry =>
      Object.keys(entry).find(key => new RegExp(search).test(entry[key]))
    );
  };

  const renderEntries = () => {
    const filtered = filteredEntries();
    return (
      <>
        {filtered.map(entry => (
          <tr key={entry.gid}>
            <td className="border-t px-4 py-2 w-1/2">{renderEntryEmails(entry)}</td>
            <td className="border-t px-4 py-2 w-1/4">{entry.name_first}</td>
            <td className="border-t px-4 py-2 w-1/4">{entry.name_last}</td>
          </tr>
        ))}
      </>
    );
  };

  const renderMailingList = () =>  (
    <div>
      <table className="sg-mgmt-table">
        <thead>
          <tr>
            <th className="bg-ui-3 text-white px-4 py-2 text-left">Email Address</th>
            <th className="bg-ui-3 text-white px-4 py-2 text-left">First Name</th>
            <th className="bg-ui-3 text-white px-4 py-2 text-left">Last Name</th>
          </tr>
        </thead>
        <tbody>{renderEntries()}</tbody>
      </table>
    </div>
  );

  const renderSearch = () => (
    <div className="flex-none">
      <input
        className="sg-mgmt-form-input"
        type="text"
        placeholder="Search"
        onChange={e => setSearch(e.target.value)}
      />
    </div>
  );

  const renderModalBody = () => {
    if (fetched) {
      return (
        <div>
          <div className="flex items-end mb-2">
            {renderSearch()}
          </div>
          {renderMailingList()}
          <br />
          {renderCancelButton("Close", modalClose)}
        </div>
      );
    }
    return <Loading />;
  }

  const renderTitle = () => {
    if (fetched) { return list.name };
    return "";
  };

  return (
    <Modal
      className="sg-mgmt-modal"
      contentLabel="Mailing List Preview"
      isOpen={modalVisible}
      overlayClassName="sg-mgmt-modal-overlay"
      onRequestClose={modalClose}
    >
      <div className="sg-mgmt-modal-frame">
        <div className="sg-mgmt-modal-title">{renderTitle()}</div>
        <div className="sg-mgmt-modal-content">
          {renderModalBody()}
        </div>
      </div>
    </Modal>
  );
};

export default CommunicationsEmailMailingListPreviewModal;
