import React, { useState } from "react";
import CommunicationsEmailMailingListImportForm from "./CommunicationsEmailMailingListImportForm";
import CommunicationsEmailMailingListImportMapping from "./CommunicationsEmailMailingListImportMapping";

const CommunicationsEmailMailingListImport = ({
  back = () => {},
  goEdit,
  listId
}) => {
  const [stage, setStage] = useState(0);
  const [sheet, setSheet] = useState(null);

  const successStage1 = sh => {
    setSheet(sh);
    setStage(1);
  };

  const successStage2 = () => {
    goEdit();
  };

  const renderStage = () => {
    switch (stage) {
      case 0:
        return (
          <CommunicationsEmailMailingListImportForm
            back={back}
            listId={listId}
            callbackSuccess={successStage1}
          />
        );
      case 1:
        return (
          <CommunicationsEmailMailingListImportMapping
            listId={listId}
            sheet={sheet}
            callbackSuccess={successStage2}
          />
        );
      default:
        console.log("Invalid stage");
        return <></>;
    }
  };

  return <div>{renderStage()}</div>;
};

export default CommunicationsEmailMailingListImport;
