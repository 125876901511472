import React, { useState } from "react";
import { renderRegexField, renderTextField } from "@shared/FormUtils";

const EventDetailsRegistrationSectionOptionsNew = ({
  field
}) => {
  const blankField = { slug: "", label: "" };
  const startIdx = field.options.length;
  const [options, setOptions] = useState([]);

  const addOption = () => {
    setOptions(prevOptions => prevOptions.concat([blankField]));
  };

  const renderOptionsField = idx => {
    return (
      <div key={idx}>
        <div>
          {renderTextField(
            "Slug",
            `field[event_field_options][${idx}][slug]`
          )}
        </div>
        <div>
          {renderTextField(
            "Label (question)",
            `field[event_field_options][${idx}][label]`
          )}
        </div>
      </div>
    );
  };

  const renderOptionsFields = () => {
    return (
      <div>
        {options.map((_opt, idx) => renderOptionsField(idx + startIdx))}
        <span className="sg-mgmt-link" onClick={addOption}>
          + Add another option
        </span>
      </div>
    );
  };

  return renderOptionsFields();
};

export default EventDetailsRegistrationSectionOptionsNew;
