import React from "react";

import { Stack } from "@mui/material";

import { renderCancelButton, renderButton } from "@shared/FormUtils";
import PageHeader from "@shared/PageHeader";

const CustomReportSave = (props) => {
  const {
    goBack,
    report,
    reportName,
    // reportLocation,
    // reportEditable,
    setReportName,
    // setReportLocation,
    // setReportEditable,
    submit
  } = props;

  const renderButtons = () => {
    const disabled = !reportName || reportName.length === 0;
    return (
      <div className="report-select-keys report-select-keys-divider">
        <Stack spacing={2} direction="row">
          {renderButton("Save", submit, {
            disabled: disabled,
            color: "secondary"
          })}
          {renderCancelButton("Cancel", goBack, {
            color: "secondary"
          })}
        </Stack>
      </div>
    );
  };

  // const renderReportLocation = () => {
  //   let locationObjects = [
  //     { value: false, label: "Reports (shared)" },
  //     { value: true, label: "My Reports (private)" }
  //   ];
  //   return (
  //     <>
  //       <div className="report-select-keys">
  //         <label>Report Location:</label>
  //         <SelectField
  //           value={reportLocation}
  //           options={locationObjects}
  //           onChange={(location) => {
  //             setReportLocation(location);
  //           }}
  //         />
  //       </div>
  //       {reportLocation.value === false ? (
  //         <>
  //           <div className="report-select-keys">
  //             <label>Editable by others:</label>
  //             <input
  //               type="checkbox"
  //               checked={reportEditable}
  //               onChange={(e) => {
  //                 setReportEditable(e);
  //               }}
  //               style={{ width: "auto" }}
  //             />
  //           </div>
  //         </>
  //       ) : (
  //           ""
  //         )}
  //     </>
  //   );
  // };

  const renderSaveAs = () => {
    return (
      <>
        <label>Report Title:</label>
        <input
          type="text"
          value={reportName}
          onChange={(e) => setReportName(e.target.value)}
          className="report-tool-input ml-2"
        />
        <br />
      </>
    );
  };

  return (
    <div className="report-tool-form-wrapper" style={{ margin: "0px" }}>
      <div>
        <PageHeader subHeader text="Save Report" />
      </div>
      <div className="mt-2">
        {renderSaveAs()}
        {renderButtons()}
      </div>
    </div>
  );
};

export default CustomReportSave;
