import React, { useContext, useEffect, useState } from "react";

import { FileCopy as ContentCopyIcon } from "@mui/icons-material";
import { Button } from "@mui/material";
import axios from "axios";
import { useConfirm } from "material-ui-confirm";
import { useNavigate, Link } from "react-router";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import EventUserContext from "@event/EventUserContext";
import { alertError, alertHttpError, alertSuccess } from "@shared/Alerts";
import { renderCreateButton } from "@shared/FormUtils";
import GrowlTable from "@shared/GrowlTable";
import Loading from "@shared/Loading";
import PageHeader from "@shared/PageHeader";
import { formatDate } from "@shared/TimeUtils";

import FormCopyModal from "./copy/FormCopyModal";
import { isFormPreviewable, previewById } from "./utils/FormPreviewUtils";

const FormsFormsIndex = (props) => {
  const { form_type } = props;
  const { apiRoot, event } = useContext(EventContext).values;
  const { user } = useContext(EventUserContext);
  const [forms, setForms] = useState([]);
  const [fetched, setFetched] = useState(false);
  const [copyFormModalOpen, setCopyFormModalOpen] = useState(false);
  const navigate = useNavigate();
  const confirm = useConfirm();

  useEffect(() => {
    const fetchForms = async () => {
      try {
        const result = await axios(urljoin(apiRoot, "forms"));
        setForms(
          result.data["forms"].filter((x) => {
            if (form_type) {
              return x.form_type == form_type;
            }
            return true;
          })
        );
        setFetched(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchForms();
  }, [apiRoot, form_type]);

  const columns = [
    {
      headerName: "Form Name",
      field: "name",
      flex: 1,
      renderCell: (params) => (
        <>
          <b>{params.value}</b>
        </>
      )
    },
    {
      headerName: "Form Type",
      field: "form_type",
      flex: 1
    },
    {
      headerName: "Form ID",
      field: "form_id",
      flex: 1
    },
    {
      headerName: "Updated At",
      field: "updated_at",
      flex: 1,
      renderCell: (params) => {
        return formatDate(params.value);
      }
    },
    {
      headerName: "Updated By",
      field: "updated_by",
      flex: 1,
      renderCell: (params) => {
        return params.value ? (
          <>
            {params.value.name_first} {params.value.name_last}
          </>
        ) : (
          <></>
        );
      }
    },
    {
      headerName: "Submissions",
      field: "forms_form_submissions_counter",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <a
              style={{ width: "80px" }}
              className="sg-mgmt-link w-80px block text-center"
              href="#"
              onClick={() => viewForm(params.row)}
            >
              {params.row.forms_form_submissions_counter}
            </a>
          </>
        );
      }
    },
    {
      headerName: "Actions",
      field: "actions",
      type: "actions",
      flex: 1,
      minWidth: 260,
      getActions: (params) => [
        renderPreviewAction(params.row),
        renderEditAction(params.row),
        renderDeleteAction(params.row),
        renderCloneAction(params.row)
      ]
    }
  ];

  const renderPreviewAction = (item) => {
    if (isFormPreviewable(item)) {
      return (
        <>
          <span
            className="cursor-pointer"
            onClick={() => {
              previewById(item.id, apiRoot);
            }}
          >
            Preview
          </span>
        </>
      );
    }
    return <span className="text-gray-500">Preview</span>;
  };

  const cloneForm = (item) => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      url: urljoin(apiRoot, `/forms/${item.id}/clone`),
      method: "POST"
    })
      .then((response) => {
        if (response.data.error === null) {
          const new_form = response.data.form;
          navigate(`/forms/${form_type}/${new_form.id}`);
        } else {
          alertError(response.data.error);
        }
      })
      .catch((error) => {
        alertHttpError(error);
      });
  };

  const renderCloneAction = (item) => {
    if (!editEnabled()) {
      return <></>;
    }

    return (
      <>
        <span
          className="cursor-pointer"
          onClick={() => {
            cloneForm(item);
          }}
        >
          Clone
        </span>
      </>
    );
  };

  const renderEditAction = (item) => {
    if (!editEnabled()) {
      return <></>;
    }

    return (
      <>
        <span
          className="cursor-pointer"
          onClick={() => {
            editForm(item);
          }}
        >
          Edit
        </span>
      </>
    );
  };

  const attemptDelete = (form) => {
    confirm({
      title: "Confirm removal",
      description: "Are you sure you want to remove this form? This cannot be undone."
    })
      .then(() => {
        performDelete(form);
      })
      .catch((err) => {
        alertError(err);
      });
  };

  const renderDeleteAction = (item) => {
    if (editEnabled()) {
      return (
        <>
          <span
            className="cursor-pointer"
            onClick={() => {
              attemptDelete(item);
            }}
          >
            Delete
          </span>
        </>
      );
    }
    return <></>;
  };

  const performDelete = (form) => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      url: urljoin(apiRoot, "/forms/", `${form.id}`),
      method: "DELETE"
    })
      .then((response) => {
        if (response.data.error === null) {
          deleteForm(form);
          alertSuccess("Form deleted successfully");
        } else {
          alertError(response.data.error);
        }
      })
      .catch((error) => {
        alertHttpError(error);
      });
  };

  const editForm = (form) => {
    //TODO go to edit page
    navigate(`/forms/${form_type}/${form.id}`);
  };

  const viewForm = (form) => {
    //TODO go to edit page
    navigate(`/forms/${form_type}/${form.id}/view`);
  };

  const deleteForm = (form) => {
    setForms(forms.filter((f) => f.id !== form.id));
  };

  const editEnabled = () => {
    if (user.role === "basic" && !user.permission.forms_edit) {
      return false;
    }
    return true;
  };

  const renderFormTable = () => {
    if (!fetched) {
      return <Loading />;
    }

    return (
      <GrowlTable
        columns={columns}
        items={forms}
        sortColumn="name"
        sortDirection="asc"
        tableName={`${event.slug}-forms`}
        toolbarControls={[
          <Button
            key="copy-form"
            size="small"
            disabled={!editEnabled()}
            onClick={toggleFormCopyModal}
            startIcon={<ContentCopyIcon size="small" />}
          >
            Copy Form
          </Button>
        ]}
      />
    );
  };

  const renderNewFormButton = () => {
    if (editEnabled()) {
      return <Link to={`/forms/${form_type}/new`}>{renderCreateButton("New Form", () => {})}</Link>;
    }
  };

  const renderFormCopyModal = () => {
    return (
      <FormCopyModal
        modalVisible={copyFormModalOpen}
        resetModal={toggleFormCopyModal}
        formType={form_type}
        callbackFailure={() => {}}
        callbackSuccess={(form) => {
          navigate(`/forms/${form_type}/${form.id}`);
        }}
      />
    );
  };

  const toggleFormCopyModal = () => {
    setCopyFormModalOpen(!copyFormModalOpen);
  };

  return (
    <div>
      <PageHeader text="Form Management" />
      {renderNewFormButton()}
      <br />
      <br />
      {renderFormTable()}
      {renderFormCopyModal()}
    </div>
  );
};

export default FormsFormsIndex;
