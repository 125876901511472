import React, { useContext, useEffect, useState } from "react";

import axios from "axios";
import sortBy from "lodash/sortBy";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import { alertHttpError } from "@shared/Alerts";

import HousingConfigurationBillingInstructions from "./HousingConfigurationBillingInstructions";
import HousingConfigurationEdit from "./HousingConfigurationEdit";
import HousingConfigurationInfo from "./HousingConfigurationInfo";

const HousingConfiguration = () => {
  const { apiRoot } = useContext(EventContext).values;
  const [page, setPage] = useState("info");
  const [fetched, setFetched] = useState(false);
  const [configuration, setConfiguration] = useState({});
  const [instructions, setInstructions] = useState([]);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const result = await axios(urljoin(apiRoot, "/housing/configuration"));
        setConfiguration(result.data);
        setFetched(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    const fetchInstructions = async () => {
      try {
        const result = await axios(
          urljoin(apiRoot, "/housing/billing_instructions")
        );
        setInstructions(sortInstructions(result.data));
        setFetched(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchInstructions();
    fetchConfig();
  }, [apiRoot]);

  const sortInstructions = instructionsToSort =>
    sortBy(instructionsToSort, ["sort_order"]);

  const goToPage = p => {
    setPage(p);
  };

  const renderPage = () => {
    switch (page) {
      case "info":
        return (
          <HousingConfigurationInfo
            configuration={configuration}
            fetched={fetched}
            goEdit={() => goToPage("edit")}
            goInstructions={() => goToPage("instructions")}
            instructions={instructions}
          />
        );
      case "edit":
        return (
          <HousingConfigurationEdit
            back={() => goToPage("info")}
            configuration={configuration}
            updateFunc={setConfiguration}
          />
        );
      case "instructions":
        return (
          <HousingConfigurationBillingInstructions
            back={() => goToPage("info")}
          />
        );
      default:
        throw new Error("invalid housing page");
    }
  };

  return <div>{renderPage()}</div>;
};

export default HousingConfiguration;
