import React from "react";
import Modal from "react-modal";
import { alertError, alertSuccess } from "@shared/Alerts";
import SessionsVenueForm from "./SessionsVenueForm";

const SessionsVenueModal = props => {
  const {
    venue,
    modalVisible,
    resetModal,
    title,
    updateFunc
  } = props;

  Modal.setAppElement("#root");

  const success = response => {
    alertSuccess("Venue saved successfully");
    updateFunc(response.data.venue);
    resetModal();
  };

  const failure = () => {
    alertError("Failed to save venue");
  };

  return (
    <Modal
      className="sg-mgmt-modal"
      contentLabel={title}
      isOpen={modalVisible}
      overlayClassName="sg-mgmt-modal-overlay"
      onRequestClose={resetModal}
    >
      <div className="sg-mgmt-modal-frame">
        <div className="sg-mgmt-modal-title">{title}</div>
        <div className="sg-mgmt-modal-content">
          <SessionsVenueForm
            callbackFailure={failure}
            callbackSuccess={success}
            cancelButton={resetModal}
            venue={venue}
          />
        </div>
      </div>
    </Modal>
  );
};

export default SessionsVenueModal;
