import React, { useContext, useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";

import { Autocomplete, TextField } from "@mui/material";
import axios from "axios";
import {
  eachDayOfInterval,
  eachHourOfInterval,
  isEqual,
  parseISO
} from "date-fns";
import { toDate, format } from "date-fns-tz";
import sortBy from "lodash/sortBy";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import EventUserContext from "@event/EventUserContext";
import { alertError, alertHttpError, alertSuccess } from "@shared/Alerts";
import Loading from "@shared/Loading";
import PageHeader from "@shared/PageHeader";

import EventMeetings1On1ModalAdd from "./EventMeetings1On1ModalAdd";
import EventMeetings1On1ModalEdit from "./EventMeetings1On1ModalEdit";
import EventMeetings1On1ModalView from "./EventMeetings1On1ModalView";

const EventMeetings1On1Schedule = props => {
  const { apiRoot, event } = useContext(EventContext).values;
  const { user } = useContext(EventUserContext);
  const { setTab, setReviewRequestId } = props;
  const [fetchedParticipants, setFetchedParticipants] = useState(false);
  const [fetchedHosts, setFetchedHosts] = useState(false);
  const [hosts, setHosts] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [modalAdd, setModalAdd] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalView, setModalView] = useState(false);
  const [editMeetingId, setEditMeetingId] = useState(null);
  const [viewMeetingId, setViewMeetingId] = useState(null);

  const meetingStartDate = event.date_begin;
  const meetingEndDate = event.date_end;

  // if (document.body.dataset.loadtest === "true") {
  //   meetingStartDate = "2020-10-01";
  //   meetingEndDate = "2020-10-02";
  // }

  const [showDate, setShowDate] = useState(meetingStartDate);

  useEffect(() => {
    const fetchHosts = async () => {
      try {
        const result = await axios(urljoin(apiRoot, "meeting_hosts"));
        setHosts(result.data.hosts);
        setFetchedHosts(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    const fetchParticipants = async () => {
      try {
        const result = await axios(urljoin(apiRoot, "participants"));
        setParticipants(result.data.participants);
        setFetchedParticipants(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchHosts();
    fetchParticipants();
  }, []);

  const modalAddOpen = () => {
    setModalAdd(true);
  };

  const modalAddClose = () => {
    setModalAdd(false);
  };

  const editMeeting = id => {
    setEditMeetingId(id);
    setModalEdit(true);
  };

  const modalEditClose = () => {
    setModalEdit(false);
    setEditMeetingId(null);
  };

  const viewMeeting = id => {
    setViewMeetingId(id);
    setModalView(true);
  };

  const modalViewClose = () => {
    setModalView(false);
    setViewMeetingId(null);
  };

  const updateHost = host => {
    setHosts(hosts.map(h => (h.id === host.id ? host : h)));
  };

  const selectDay = value => {
    setShowDate(value.value);
  };

  const displayDate = date => format(date, "EEEE, MMMM do");
  const valueDate = date => format(date, "yyyy-MM-dd");

  const addBlock = (host, datetime) => {
    const postData = {
      meeting_host_id: host.id,
      date_and_time: datetime,
      length_minutes: 45
    };
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      url: urljoin(apiRoot, "/meeting_hosts/", `${host.id}`, "/blocks"),
      method: "POST",
      data: postData
    })
      .then(response => {
        if (response.data.error === null) {
          updateHost(response.data.host);
        } else {
          alertError("failed to add block");
        }
      })
      .catch(error => {
        alertHttpError(error);
      });
  };

  const removeBlock = (host, blockId) => {
    const postData = {
      meeting_host_id: host.id
    };
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      url: urljoin(
        apiRoot,
        "/meeting_hosts/",
        `${host.id}`,
        "/blocks",
        `${blockId}`
      ),
      method: "DELETE",
      data: postData
    })
      .then(response => {
        if (response.data.error === null) {
          updateHost(response.data.host);
        } else {
          alertError("failed to remove block");
        }
      })
      .catch(error => {
        alertHttpError(error);
      });
  };

  const removeMeeting = meetingId => {
    const postData = {};
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      url: urljoin(apiRoot, "/meeting1on1s/", `${meetingId}`),
      method: "DELETE",
      data: postData
    })
      .then(response => {
        if (response.data.error === null) {
          alertSuccess("Deleted meeting, refreshing page to reflect change");
          window.location.reload(false);
        } else {
          alertError("failed to remove block");
        }
      })
      .catch(error => {
        alertHttpError(error);
      });
  };

  const renderDateSelect = () => {
    const dateRange = eachDayOfInterval({
      start: parseISO(meetingStartDate),
      end: parseISO(meetingEndDate)
    });

    return (
      <div className="sg-mgmt-1on1-date-control">
        <div className="sg-mgmt-1on1-dateselect-label">Date:</div>
        <div className="sg-mgmt-1on1-dateselect-container">
          <Autocomplete
            options={dateRange.map(x => {
              return { label: displayDate(x), value: valueDate(x) };
            })}
            onChange={(e, value) => selectDay(value)}
            renderInput={params => (
              <TextField name="" autoComplete="off" {...params} />
            )}
            disablePortal
            size="small"
          />
        </div>
      </div>
    );
  };

  const renderAddButton = () => {
    if (user.role === "executive") {
      return (
        <div className="sg-mgmt-content-header-button" onClick={modalAddOpen}>
          <img src="/images/icons/plus-orange-round.svg" alt="Add Meeting" />
        </div>
      );
    }
    return <></>;
  };

  // const tooltipPosition = ({ left, top }, _currentEvent, currentTarget, node) => {
  //   console.log("node: " + node);
  //   console.log("currentTarget: " + currentTarget);
  //   return { top: top, left: left };
  // };

  const renderHourBlock = (host, datetime) => {
    const blocks = host.meeting_host_blocks.filter(blk =>
      isEqual(parseISO(blk.date_and_time_local), datetime)
    );
    const meetings = host.meeting_host_attendances.filter(mtg =>
      isEqual(parseISO(mtg.date_and_time_local), datetime)
    );
    const requests = host.meeting_request_host_attendances.filter(req =>
      isEqual(parseISO(req.date_and_time_local), datetime)
    );

    if (blocks.length > 0) {
      const block = blocks[0];
      return (
        <td key={`${host.id}-${Date.parse(datetime)}`}>
          <div
            className="sg-mgmt-1on1-table-cell sg-mgmt-1on1-block"
            data-tip
            data-for={`block-${block.id}`}
            onClick={() => removeBlock(host, block.id)}
          />
          <ReactTooltip
            id={`block-${block.id}`}
            border
            borderColor="gray"
            type="dark"
          >
            <span className="sg-mgmt-link">Unblock</span>
          </ReactTooltip>
        </td>
      );
    }

    if (meetings.length > 0) {
      const meeting = meetings[0];
      return (
        <td key={`${host.id}-${Date.parse(datetime)}`}>
          <div
            className="sg-mgmt-1on1-table-cell sg-mgmt-1on1-meeting"
            data-for={`edit-${host.id}-${Date.parse(datetime)}`}
            data-tip
            onClick={() => viewMeeting(meeting.meeting_id)}
          />
          <ReactTooltip
            id={`edit-${host.id}-${Date.parse(datetime)}`}
            border
            borderColor="gray"
            type="dark"
          >
            <span className="sg-mgmt-link">View/Edit</span>
          </ReactTooltip>
        </td>
      );
    }

    if (requests.length > 0) {
      const request = requests[0];
      return (
        <td key={`${host.id}-${Date.parse(datetime)}`}>
          <div
            className="sg-mgmt-1on1-table-cell sg-mgmt-1on1-request"
            data-for={`edit-${host.id}-${Date.parse(datetime)}`}
            data-tip
            onClick={() => {
              setReviewRequestId(request.meeting_request_id);
              setTab(2);
            }}
          />
          <ReactTooltip
            id={`edit-${host.id}-${Date.parse(datetime)}`}
            border
            borderColor="gray"
            type="dark"
          >
            <span className="sg-mgmt-link">Review</span>
          </ReactTooltip>
        </td>
      );
    }

    return (
      <td key={`${host.id}-${Date.parse(datetime)}`}>
        <div
          className="sg-mgmt-1on1-table-cell sg-mgmt-1on1-available"
          data-for={`open-${host.id}-${Date.parse(datetime)}`}
          data-tip
          onClick={() => addBlock(host, datetime)}
        />
        <ReactTooltip
          id={`open-${host.id}-${Date.parse(datetime)}`}
          border
          borderColor="gray"
          type="dark"
        >
          <span className="sg-mgmt-link">Block</span>
        </ReactTooltip>
      </td>
    );
  };

  // Keeping this commented out for when Sumo changes their mind again
  // const renderHourBlocks = host => {
  //   const hourRange1 = eachHourOfInterval({
  //     start: toDate(`${showDate} 07:00:00 ${event.time_zone}`),
  //     end: toDate(`${showDate} 13:00:00 ${event.time_zone}`)
  //   });
  //   const hourRange2 = eachHourOfInterval({
  //     start: toDate(`${showDate} 16:00:00 ${event.time_zone}`),
  //     end: toDate(`${showDate} 19:00:00 ${event.time_zone}`)
  //   });
  //   return (
  //     <>
  //       {hourRange1.map(dt => renderHourBlock(host, dt))}
  //       {hourRange2.map(dt => renderHourBlock(host, dt))}
  //     </>
  //   );
  // };

  const renderHourBlocks = host => {
    const hourRange = eachHourOfInterval({
      start: toDate(`${showDate} 06:00:00 ${event.time_zone}`),
      end: toDate(`${showDate} 16:00:00 ${event.time_zone}`)
    });
    return <>{hourRange.map(dt => renderHourBlock(host, dt))}</>;
  };

  const renderHost = host => (
    <tr key={host.gid}>
      <td className="sg-mgmt-1on1-table-name">{`${host.name_first} ${host.name_last}`}</td>
      {renderHourBlocks(host)}
    </tr>
  );

  const renderHosts = () => {
    const sorted = sortBy(hosts, ["name_last"]);
    return sorted.map(h => renderHost(h));
  };

  const renderMeetingAdd = () => {
    if (document.body.dataset.environment === "production") {
      return <></>;
    }
    return (
      <div>
        <a
          className="sg-mgmt-link"
          href="https://web-sandbox.silentgrowl.com/meetings"
        >
          Add a meeting
        </a>
      </div>
    );
  };

  const renderDownloadReport = () => {
    return (
      <div>
        <a
          className="sg-mgmt-link"
          href={`/reports/events/${event.slug}/exec_1on1`}
          data-turbolinks="false"
        >
          Download Report
        </a>
      </div>
    );
  };

  const renderSchedule = () => {
    if (!event.date_begin || !event.date_end) {
      return (
        <div>
          <p>
            You must set start and end dates for this event in the Event
            Settings before using the meetings section.
          </p>
        </div>
      );
    }
    if (fetchedHosts && fetchedParticipants) {
      return (
        <div>
          <div className="sg-mgmt-1on1-links">
            {renderMeetingAdd()}
            {renderDownloadReport()}
          </div>
          <div className="sg-mgmt-1on1-legend">
            <div className="sg-mgmt-1on1-legend-box sg-mgmt-1on1-available" />
            <div className="sg-mgmt-1on1-legend-label">Available</div>
            <div className="sg-mgmt-1on1-legend-box sg-mgmt-1on1-request" />
            <div className="sg-mgmt-1on1-legend-label">Requested</div>
            <div className="sg-mgmt-1on1-legend-box sg-mgmt-1on1-meeting" />
            <div className="sg-mgmt-1on1-legend-label">Booked</div>
            <div className="sg-mgmt-1on1-legend-box sg-mgmt-1on1-block" />
            <div className="sg-mgmt-1on1-legend-label">Blocked out</div>
          </div>
          {renderDateSelect()}
          <table className="sg-mgmt-1on1-table">
            <thead>
              <tr>
                <th className="sg-mgmt-1on1-table-name">&nbsp;</th>
                <th>6:00&nbsp;am</th>
                <th>7:00&nbsp;am</th>
                <th>8:00&nbsp;am</th>
                <th>9:00&nbsp;am</th>
                <th>10:00&nbsp;am</th>
                <th>11:00&nbsp;am</th>
                <th>12:00&nbsp;pm</th>
                <th>1:00&nbsp;pm</th>
                <th>2:00&nbsp;pm</th>
                <th>3:00&nbsp;pm</th>
                <th>4:00&nbsp;pm</th>
              </tr>
            </thead>
            <tbody>{renderHosts()}</tbody>
          </table>
        </div>
      );
    }
    return <Loading />;
  };

  return (
    <div>
      <div className="sg-mgmt-content-header">
        {renderAddButton()}
        <PageHeader text="1:1 Meeting Scheduler" />
      </div>
      <div className="sg-mgmt-event-meetings-wrapper">{renderSchedule()}</div>
      <EventMeetings1On1ModalAdd
        participants={participants}
        hosts={hosts}
        modalVisible={modalAdd}
        resetModal={modalAddClose}
        updateHost={updateHost}
        updateHosts={setHosts}
      />
      <EventMeetings1On1ModalEdit
        participants={participants}
        hosts={hosts}
        meetingId={editMeetingId}
        modalVisible={modalEdit}
        resetModal={modalEditClose}
        updateHost={updateHost}
        updateHosts={setHosts}
      />
      <EventMeetings1On1ModalView
        editMeeting={editMeeting}
        meetingId={viewMeetingId}
        modalVisible={modalView}
        removeMeeting={removeMeeting}
        resetModal={modalViewClose}
      />
    </div>
  );
};

export default EventMeetings1On1Schedule;
