import React from "react";
import Modal from "react-modal";
import EventUsersForm from "./EventUsersForm";

const EventUsersModal = ({
  eventUsers = [],
  modalVisible,
  resetModal,
  title,
  updateFunc,
  user,
  users = []
}) => {

  Modal.setAppElement("#root");

  return (
    <Modal
      className="sg-mgmt-modal"
      contentLabel={title}
      isOpen={modalVisible}
      overlayClassName="sg-mgmt-modal-overlay"
      onRequestClose={resetModal}
    >
      <div className="sg-mgmt-modal-frame">
        <div className="sg-mgmt-modal-title">{title}</div>
        <div className="sg-mgmt-modal-content">
          <EventUsersForm
            eventUsers={eventUsers}
            reset={resetModal}
            update={updateFunc}
            user={user}
            users={users}
          />
        </div>
      </div>
    </Modal>
  );
};

export default EventUsersModal;
