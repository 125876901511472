import React, { useState } from "react";

import ContentFrame from "@shared/ContentFrame";
import ErrorFallback from "@shared/ErrorFallback";
import Tabs from "@shared/tabs/Tabs";
import { useAppsignal } from "@shared/useAppsignal";

const TabbedPage = (props) => {
  const { tabs } = props;
  const { appsignal, ErrorBoundary } = useAppsignal();
  const [tab, setTab] = useState(0);
  const [forceUpdate, setForceUpdate] = React.useState(Date.now());

  const changeTab = (newTab) => {
    if (tab !== newTab) {
      setTab(newTab);
    } else {
      // force re-render to reload current tab
      setForceUpdate(Date.now());
    }
  };

  const renderTabContent = () => {
    const RefreshableComponent = tabs[tab].component;

    return (
      <>
        <RefreshableComponent forceUpdate={forceUpdate} setTab={setTab} {...tabs[tab].componentProps} />
      </>
    );
  };

  const renderTabs = () => {
    return (
      <Tabs
        tabs={tabs.map((tab, index) => {
          return {
            id: index,
            name: tab.label,
            onClick: () => changeTab(index),
            icon: tab.icon
          };
        })}
        activeTabId={tab}
      />
    );
  };

  return (
    <div className="w-full max-w-full overflow-hidden">
      {renderTabs()}
      <ContentFrame>
        <ErrorBoundary instance={appsignal} fallback={(_error) => <ErrorFallback />}>
          {renderTabContent()}
        </ErrorBoundary>
      </ContentFrame>
    </div>
  );
};

export default TabbedPage;
