import React, { useContext } from "react";

import { Stack } from "@mui/material";
import axios from "axios";
import clsx from "clsx";
import { Formik, Form } from "formik";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import { alertError, alertHttpError } from "@shared/Alerts";
import { renderSubmitButton } from "@shared/FormUtils";
import SelectField from "@shared/SelectField";

const CommunicationsEmailMailingListImportMapping = (props) => {
  const { apiRoot } = useContext(EventContext).values;
  const { callbackSuccess, listId, sheet } = props;

  const mapRow1 = sheet.mapping_preview[0].split("[|]");
  const mapRow2 = sheet.mapping_preview[1].split("[|]");

  const formConfig = {
    alert: "added",
    formId: "sg-mgmt-form-list-mapping",
    formUrl: urljoin(apiRoot, `/communications/lists/${listId}/import/${sheet.id}/mapping`),
    method: "POST",
    title: "Map Fields"
  };

  const formInitialValues = () => {
    return {
      mapping: sheet.mapping_autodetect || {}
    };
  };

  const generateKey = (prefix) => {
    if (!prefix) {
      return `${Math.floor(Math.random() * 10000)}-${new Date().getTime()}`;
    }
    return `${prefix}-${Math.floor(Math.random() * 10000)}-${new Date().getTime()}`;
  };

  const fieldOptions = [
    { label: "(unused)", value: "" },
    { label: "Email", value: "email" },
    { label: "First Name", value: "name_first" },
    { label: "Last Name", value: "name_last" },
    { label: "CC address", value: "cc_address" },
    { label: "BCC address", value: "bcc_address" },
    { label: "Custom Tag 1", value: "custom_tag_1" },
    { label: "Custom Tag 2", value: "custom_tag_2" },
    { label: "Custom Tag 3", value: "custom_tag_3" },
    { label: "Custom Tag 4", value: "custom_tag_4" },
    { label: "Custom Tag 5", value: "custom_tag_5" }
  ];

  const renderFieldSelect = (idx, formatClasses = []) => {
    // increment the index value as the Excel sheets count from 1
    return (
      <div className={clsx("sg-mgmt-form-input-container", formatClasses)}>
        <SelectField fieldName={`import[mapping][${idx + 1}]`} options={fieldOptions} />
      </div>
    );
  };

  const renderSelectFields = () => (
    <tr>
      {Array.from(Array(mapRow1.length)).map((_, i) => (
        <td key={`mapping-field-${i}`}>{renderFieldSelect(i)}</td>
      ))}
    </tr>
  );

  const renderPreviewRow = (preview) => (
    <tr>
      {preview.map((val) => (
        <td key={generateKey(val)}>{val}</td>
      ))}
    </tr>
  );

  const submitEnable = (isSubmitting) => {
    return isSubmitting;
  };

  const renderMailingListImportMapping = () => {
    return (
      <Formik
        initialValues={{
          import: formInitialValues()
        }}
        onSubmit={(values, { setSubmitting }) => {
          const form = document.getElementById(formConfig.formId);
          const formData = new FormData(form);
          const token = document.querySelector("[name=csrf-token]").content;
          axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

          const mappedValues = Object.values(values.import.mapping);
          let abort = false;

          // TODO: figure out this kind of non-field-specific validation with
          // a more Formik idiomatic approach
          if (!mappedValues.includes("email")) {
            alertError("You must identify a field with email addresses");
            abort = true;
          }

          if (mappedValues.filter((e) => e === "email").length > 1) {
            alertError("You must have only one field identified as Email");
            abort = true;
          }

          if (mappedValues.filter((e) => e === "name_first").length > 1) {
            alertError("You must have only one field identified as First Name");
            abort = true;
          }

          if (mappedValues.filter((e) => e === "name_last").length > 1) {
            alertError("You must have only one field identified as Last Name");
            abort = true;
          }

          if (abort) {
            setSubmitting(false);
            return;
          }

          axios({
            url: formConfig.formUrl,
            method: formConfig.method,
            data: formData
          })
            .then((response) => {
              if (response.data.error == null) {
                callbackSuccess();
              } else {
                alertError(response.data.error);
                setSubmitting(false);
              }
            })
            .catch((error) => {
              alertHttpError(error);
            });
        }}
      >
        {({ isSubmitting }) => (
          <Form className="sg-mgmt-form" id={formConfig.formId}>
            <div className="sg-mgmt-form-container">
              <div className="sg-mgmt-form-section">
                <h2>Import Contacts</h2>
                <p>Match columns to the fields they represent.</p>
                <p>
                  Multiple columns can be flagged for CC: and BCC: lists, but only one Email, First Name, and Last Name
                  columns are allowed.
                </p>
                <div className="sg-mgmt-form-row">
                  <div className="sg-mgmt-form-row-column">
                    <div className="sg-mgmt-table-import-container">
                      <table className="sg-mgmt-table sg-mgmt-table-import">
                        <tbody>
                          {renderSelectFields()}
                          {renderPreviewRow(mapRow1)}
                          {renderPreviewRow(mapRow2)}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Stack direction="row" spacing={2}>
              {renderSubmitButton(formConfig.title, submitEnable(isSubmitting))}
            </Stack>
          </Form>
        )}
      </Formik>
    );
  };

  return <div>{renderMailingListImportMapping()}</div>;
};

export default CommunicationsEmailMailingListImportMapping;
