import React from "react";
import HelperModal from "./HelperModal";

const HelperIcon = props => {
  const {
    modalProps,
    outerClassNames
  } = props;
  const [modalOpen, setModalOpen] = React.useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  }

  return (
    <div className={`sg-mgmt-helper-icon ${outerClassNames||""}`}>
      <img src={"/images/icons/info-default.svg"} className="sg-mgmt-helper-icon-image-normal sg-mgmt-helper-icon-image" onClick={toggleModal} />
      <img src={"/images/icons/info-hovered.svg"} className="sg-mgmt-helper-icon-image-hovered sg-mgmt-helper-icon-image" onClick={toggleModal} />
      <HelperModal
        {...modalProps}
        modalOpen={modalOpen}
        closeModal={toggleModal}
      />
    </div>
  );
};

export default HelperIcon;
