import React from "react";
import Modal from "react-modal";

import Loading from "@shared/Loading";

import PeopleIndividualForm from "./PeopleIndividualForm";

const PeopleIndividualModal = props => {
  const {
    fetched,
    individual,
    modalVisible,
    resetModal,
    rootUrl,
    updateIndividual
  } = props;

  Modal.setAppElement("#root");

  const cancelButton = () => {
    resetModal();
  };

  const renderField = (label, value) => {
    return (
      <div key={label} className="sg-mgmt-modal-view-field">
        <span className="sg-mgmt-modal-view-field-label">{label}</span>
        :&nbsp;
        {value}
      </div>
    );
  };

  const renderIndividualForm = () => {
    return (
      <PeopleIndividualForm
        cancelButton={cancelButton}
        fetched={fetched}
        individual={individual}
        rootUrl={rootUrl}
        updateIndividual={updateIndividual}
      />
    );
  };

  const renderContent = () => {
    if (fetched) {
      return (
        <div className="sg-mgmt-content-full flex">
          <div className="flex-auto pr-4">
            <h2>Individual Form</h2>
            {renderIndividualForm()}
          </div>
        </div>
      );
    }
    return <Loading />;
  };

  return (
    <Modal
      className="sg-mgmt-modal"
      overlayClassName="sg-mgmt-modal-overlay"
      isOpen={modalVisible}
      onRequestClose={resetModal}
      contentLabel="View Participant"
    >
      <div className="sg-mgmt-modal-frame">
        <div className="sg-mgmt-modal-title">Edit Person</div>
        <div className="sg-mgmt-modal-content">
          <div className="sg-mgmt-modal-view">
            <div>{renderField("GID", individual.gid)}</div>
            {renderContent()}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PeopleIndividualModal;
