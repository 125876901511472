import React, { useContext } from "react";
import axios from "axios";
import { Formik, Form } from "formik";
import urljoin from "url-join";
import { renderTextField } from "@shared/FormUtils";
import EventContext from "@event/EventContext";
import SessionsBlankRoom from "./SessionsBlankRoom";
import { renderSubmitButton, renderCancelButton } from "@shared/FormUtils";
import { Stack } from "@mui/material";
const SessionsVenueRoomForm = ({
  callbackFailure = () => {},
  callbackSuccess = () => {},
  cancelButton = () => {},
  venue,
  room
}) => {
  const { apiRoot } = useContext(EventContext).values;

  const isEdit = () => {
    return room && room.id;
  };

  const formConfig = (() => {
    if (isEdit()) {
      return {
        alert: "updated",
        formId: "sg-mgmt-form-token-edit",
        formUrl: urljoin(
          apiRoot,
          "/venues",
          `/${venue.id}`,
          `/rooms/${room.id}`
        ),
        method: "PATCH",
        saveButton: "Update",
        title: "Edit Room"
      };
    }
    return {
      alert: "added",
      formId: "sg-mgmt-form-token-add",
      formUrl: urljoin(
        apiRoot,
        "/venues",
        `/${venue.id}`,
        "/rooms"
      ),
      method: "POST",
      saveButton: "Create",
      title: "Create Room"
    };
  })();

  const formInitialValues = () => {
    if (isEdit()) {
      return {
        name: room.name || "",
        location: room.location || ""
      };
    }
    return SessionsBlankRoom;
  };

  const renderForm = () => {
    return (
      <Formik
        initialValues={{
          room: formInitialValues()
        }}
        onSubmit={(values, { setSubmitting }) => {
          const form = document.getElementById(formConfig.formId);
          const formData = new FormData(form);
          const csrfToken = document.querySelector("[name=csrf-token]").content;
          axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;

          axios({
            url: formConfig.formUrl,
            method: formConfig.method,
            data: formData
          }).then(response => {
            if (response.data.error === null) {
              callbackSuccess(response);
            } else {
              callbackFailure(response);
              setSubmitting(false);
            }
          });
        }}
      >
        {({ values, isSubmitting }) => (
          <Form className="sg-mgmt-form" id={formConfig.formId}>
            <div className="sg-mgmt-form-container">
              <div className="sg-mgmt-form-row">
                {renderTextField("Room Name", "room[name]")}
              </div>
              <div className="sg-mgmt-form-row">
                {renderTextField("Location (optional)", "room[location]")}
              </div>
            </div>
            <Stack direction="row" spacing={2}>
              {renderSubmitButton(formConfig.saveButton, isSubmitting)}
              {renderCancelButton("Cancel", cancelButton)}
            </Stack>
          </Form>
        )}
      </Formik>
    );
  };

  return <>{renderForm()}</>;
};

export default SessionsVenueRoomForm;
