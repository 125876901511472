import React from "react";
import { Field } from "formik";
import {
  Checkbox,
  FormControlLabel
} from "@mui/material";
const CheckFieldSmall = ({ fieldName, label, disabled }) => (
  
  <Field name={fieldName}>
    {({ field }) => (

      <label className="sg-mgmt-checkbox-container sg-mgmt-checkbox-container-small">
        <FormControlLabel
          disabled={disabled}
          control={
            <Checkbox
              {...field}
              name={fieldName}
              checked={field.value == true}
              value={true}
              size="small"
            />
          }
          label={label}
          sx={{
            mr: 3,
            '.MuiFormControlLabel-label': {
              fontSize: 12,
            },
          }}
        />
      </label>
    )}
  </Field>
);

export default CheckFieldSmall;
