import React, { useContext, useState } from "react";

import EventContext from "@event/EventContext";
import EventUserContext from "@event/EventUserContext";
import PeopleEventParticipantsModal from "@people/participants/PeopleEventParticipantsModal";
import PeopleEventParticipantsViewModal from "@people/participants/PeopleEventParticipantsViewModal";
import GrowlTable from "@shared/GrowlTable";

const TicketingTicketAssignmentParticipantTable = (props) => {
  const {
    assignTicket,
    columns,
    individuals,
    metadataFields,
    participants,
    refreshParticipants,
    regFields,
    tags,
    types
  } = props;
  const { apiRoot, event, rootUrl } = useContext(EventContext).values;
  const { user } = useContext(EventUserContext);

  const [editParticipantId, setEditParticipantId] = useState(null);
  const [viewParticipantId, setViewParticipantId] = useState(null);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [viewModalVisible, setViewModalVisible] = useState(false);

  const editPersonClick = (id) => {
    setEditParticipantId(id);
    setEditModalVisible(true);
  };

  const addIndividual = () => {
    refreshParticipants.mutate();
  };

  const addParticipant = () => {
    refreshParticipants.mutate();
  };

  const updateParticipant = () => {
    refreshParticipants.mutate();
  };

  const editEnabled = () => {
    if (user.role === "basic" && !user.permission.participants_edit) {
      return false;
    }
    return true;
  };

  const renderEditAction = (rowData) => {
    if (!editEnabled()) {
      return <></>;
    }

    return (
      <>
        <span
          className="cursor-pointer"
          onClick={() => {
            editPersonClick(rowData.id);
          }}
        >
          Edit
        </span>
      </>
    );
  };

  const renderAssignTicket = (rowData) => {
    return (
      <>
        <span
          className="cursor-pointer"
          onClick={() => {
            assignTicketClick(rowData.id);
          }}
        >
          Assign Ticket
        </span>
      </>
    );
  };

  const assignTicketClick = (participant_id) => {
    assignTicket(participant_id);
  };

  const tableColumns = [
    ...columns,
    {
      headerName: "Actions",
      field: "actions",
      type: "actions",
      flex: 1,
      minWidth: 200,
      getActions: (params) => [renderEditAction(params.row), renderAssignTicket(params.row)]
    }
  ];

  const closeAddModal = () => {
    setAddModalVisible(false);
  };

  const closeEditModal = () => {
    setEditModalVisible(false);
  };

  const closeViewModal = () => {
    setViewModalVisible(false);
  };

  const resetAddModal = () => {
    closeAddModal();
  };

  const resetEditModal = () => {
    closeEditModal();
    setEditParticipantId(null);
  };

  const resetViewModal = () => {
    closeViewModal();
    setViewParticipantId(null);
  };

  const filteredIndividuals = () => {
    const participantIndividualIds = participants.map((p) => p.individual_id);
    return individuals.filter((ind) => !participantIndividualIds.includes(ind.id));
  };

  const renderAddModal = () => (
    <PeopleEventParticipantsModal
      apiRoot={apiRoot}
      closeModal={closeAddModal}
      addIndividual={addIndividual}
      individuals={filteredIndividuals()}
      metadataFields={metadataFields}
      regFields={regFields}
      modalVisible={addModalVisible}
      resetModal={resetAddModal}
      rootUrl={rootUrl}
      tags={tags}
      types={types}
      updateFunc={addParticipant}
    />
  );

  const renderViewModal = () => {
    if (!viewParticipantId) {
      return <></>;
    }

    return (
      <PeopleEventParticipantsViewModal
        apiRoot={apiRoot}
        participantId={viewParticipantId}
        metadataFields={metadataFields}
        modalVisible={viewModalVisible}
        regFields={regFields}
        resetModal={resetViewModal}
      />
    );
  };

  const renderEditModal = () => {
    if (!editParticipantId) {
      return <></>;
    }

    return (
      <PeopleEventParticipantsModal
        apiRoot={apiRoot}
        addIndividual={addIndividual}
        individuals={[]}
        participantId={editParticipantId}
        closeModal={closeEditModal}
        modalVisible={editModalVisible}
        metadataFields={metadataFields}
        regFields={regFields}
        resetModal={resetEditModal}
        rootUrl={rootUrl}
        tags={tags}
        types={types}
        updateFunc={updateParticipant}
      />
    );
  };

  return (
    <div>
      <GrowlTable
        columns={tableColumns}
        items={participants}
        tableName={`${event.slug}-ticket-assignment-participants`}
      />
      {renderAddModal()}
      {renderEditModal()}
      {renderViewModal()}
    </div>
  );
};

export default TicketingTicketAssignmentParticipantTable;
