import React, { useState } from "react";
import axios from "axios";
import urljoin from "url-join";
import { alertError } from "@shared/Alerts";
import { renderButton } from "@shared/FormUtils";
import LoadingMini from "@shared/LoadingMini";

const PaymentInfo = props => {
  const { eventId, urlBase, booking } = props;
  const [show, setShow] = useState(false);
  const [fetched, setFetched] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState({});

  const fetchDetails = () => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      url: urljoin(
        urlBase,
        "/portal/events",
        `/${eventId}`,
        "/bookings",
        `/${booking.id}`,
        "/payinfo"
      ),
      method: "GET"
    }).then(response => {
      if (response.data.error === null) {
        setPaymentInfo(response.data.details);
        setFetched(true);
      } else {
        alertError("Unable to fetch payment details");
      }
    });
  };

  const beginFetch = () => {
    setShow(true);
    fetchDetails();
  };

  if (fetched) {
    return (
      <div className="mb-4">
        <table>
          <tbody>
            <tr>
              <td className="pr-6 font-bold sg-mgmt-info-label">
                Cardholder Name
              </td>
              <td>{paymentInfo.card_holder}</td>
            </tr>
            {/* <tr> */}
            {/*   <td className="pr-6 font-bold sg-mgmt-info-label">Card Type</td> */}
            {/*   <td>Visa</td> */}
            {/* </tr> */}
            <tr>
              <td className="pr-6 font-bold sg-mgmt-info-label">Card Number</td>
              <td>{paymentInfo.card_number}</td>
            </tr>
            <tr>
              <td className="pr-6 font-bold sg-mgmt-info-label">
                Card Expiration
              </td>
              <td>{paymentInfo.card_expiry}</td>
            </tr>
            <tr>
              <td className="pr-6 font-bold sg-mgmt-info-label">Card CVV</td>
              <td>{paymentInfo.card_cvv}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  if (show) {
    return <LoadingMini />;
  }

  if (booking.locked) {
    return (
      renderButton(
        "Payment Details Locked",
        ()=>{},
        {
          disabled: true
        }
      )
    );
  }

  return (
    renderButton(
      "Show Payment Information",
      beginFetch
    )
  );
};

export default PaymentInfo;
