import React from "react";

import { TextField, FormControl, FormLabel } from "@mui/material";
import { Controller } from "react-hook-form";


const WidgetHeaderField = ({
  control,
  optional = false,
  placeholder = ""
}) => {
  const labelText = optional ? "Header (optional)" : "Header";

  return (
    <div className="mb-4 w-full">
      <FormControl fullWidth>
        <FormLabel>{labelText}</FormLabel>
        <Controller
          name="widget_header"
          control={control}
          render={({ field: { value, onChange, ...field } }) => (
            <>
              <TextField
                {...field}
                fullWidth
                onChange={onChange}
                value={value}
                variant="outlined"
                size="small"
                placeholder={placeholder}
              />
            </>
          )}
        />
      </FormControl>
    </div>
  );
};



export default WidgetHeaderField;
