import React from "react";

import clsx from "clsx";

const PageHeader = ({
  subHeader = false,
  text
}) => {

  return (
    <div className={clsx("text-sg-orange", "font-normal", subHeader ? "text-xl" : "text-2xl")}>{text}</div>
  );
};


export default PageHeader;
