import React, { useContext } from "react";

import { FormControl, FormLabel } from "@mui/material";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import urljoin from "url-join";

import WidgetHeaderField from "@dashboard/form/WidgetHeaderField";
import WidgetSettingsButtons from "@dashboard/form/WidgetSettingsButtons";
import WidgetSizeOptions from "@dashboard/form/WidgetSizeOptions";
import WidgetBoardContext from "@dashboard/WidgetBoardContext";
import WidgetContext from "@dashboard/WidgetContext";
import EventContext from "@event/EventContext";
import RichTextEditor from "@shared/forms/RichTextEditor";

const TextWidgetSettings = (props) => {
  const { closeModal } = props;
  const { onUpdate, widget } = useContext(WidgetContext);
  const { widgetBoard } = useContext(WidgetBoardContext);
  const { apiRoot } = useContext(EventContext).values;

  const {
    control,
    handleSubmit,
    formState: { isSubmitting }
  } = useForm({
    defaultValues: {
      widget_header: widget?.widget_header || "",
      widget_position: widget?.widget_position || 0,
      widget_size: widget?.widget_size || 1,
      widget_config: {
        text: widget?.widget_config?.text || ""
      }
    }
  });

  const formConfig = (() => {
    if (widget.id) {
      return {
        method: "PATCH",
        url: urljoin(apiRoot, "/widgets", `/${widget.id}`)
      };
    }
    return {
      method: "POST",
      url: urljoin(apiRoot, "/widgets")
    };
  })();

  const submitFn = (submitData) => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    submitData.widget_type = "text";
    submitData.widget_config.text = submitData.widget_config.text.toString("html");

    axios({
      method: formConfig.method,
      url: formConfig.url,
      data: {
        widget: submitData,
        widget_board_id: widgetBoard.id
      }
    })
      .then((result) => {
        onUpdate();
        closeModal();
        console.log(result.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const renderForm = () => {
    return (
      <form onSubmit={handleSubmit(submitFn)}>
        <div className="mt-4 mb-4">
          <WidgetHeaderField control={control} optional={true} placeholder={""} />
          <div className="mt-4 mb-4 w-full">
            <FormControl fullWidth>
              <FormLabel>Text</FormLabel>
              <Controller
                name="widget_config[text]"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <RichTextEditor value={value} onChange={onChange} />
                  // <TextField fullWidth multiline rows={4} value={value} onChange={onChange} />
                )}
              />
            </FormControl>
          </div>
          <WidgetSizeOptions control={control} />
          <WidgetSettingsButtons closeModal={closeModal} isSubmitDisabled={isSubmitting} isEdit={!!widget?.id} />
        </div>
      </form>
    );
  };

  return (
    <div className="relative bg-white group">
      <div>
        <h2>Text widget</h2>
      </div>
      <div>{renderForm()}</div>
    </div>
  );
};

export default TextWidgetSettings;
