import React, { useContext, useEffect, useState } from "react";

import axios from "axios";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import EventUserContext from "@event/EventUserContext";
import { alertError, alertHttpError, alertSuccess } from "@shared/Alerts";
import { renderCreateButton } from "@shared/FormUtils";
import GrowlTable from "@shared/GrowlTable";
import Loading from "@shared/Loading";
import PageHeader from "@shared/PageHeader";
import { formatDate } from "@shared/TimeUtils";
import UiToggle from "@shared/UiToggle";

const CommunicationsEmailMailingLists = (props) => {
  const { apiRoot, event } = useContext(EventContext).values;
  const { goEdit, goNew, setEditMailingList } = props;
  const [lists, setLists] = useState([]);
  const [fetched, setFetched] = useState(false);
  const [showArchive, setShowArchive] = useState(false);
  const { user } = useContext(EventUserContext);

  useEffect(() => {
    const fetchMailingLists = async () => {
      try {
        const result = await axios(urljoin(apiRoot, "/communications/lists"));
        setLists(result.data.lists);
        setFetched(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchMailingLists();
  }, [apiRoot]);

  const updateLists = (updatedList) => {
    setLists(lists.map((lst) => (lst.id === updatedList.id ? updatedList : lst)));
  };

  const editEnabled = () => {
    if (user.role === "basic" && !user.permission.communications_send) {
      return false;
    }
    return true;
  };

  const archive = (id) => {
    const postData = { list_id: id };
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      url: urljoin(apiRoot, "/communications/lists/", `/${id}`, "/archive"),
      method: "PATCH",
      data: postData
    }).then((response) => {
      if (response.data.error === null) {
        updateLists(response.data.list);
        alertSuccess("List archived");
      } else {
        alertError("Failed archiving list");
      }
    });
  };

  const unarchive = (id) => {
    const postData = { list_id: id };
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      url: urljoin(apiRoot, "/communications/lists/", `/${id}`, "/unarchive"),
      method: "PATCH",
      data: postData
    }).then((response) => {
      if (response.data.error === null) {
        updateLists(response.data.list);
        alertSuccess("List unarchived");
      } else {
        alertError("Failed unarchiving list");
      }
    });
  };

  const archiveToggle = (id, arch) => {
    if (arch) {
      unarchive(id);
    } else {
      archive(id);
    }
  };

  const archiveLabel = (arch) => {
    if (arch) {
      return "Unarchive";
    }
    return "Archive";
  };

  const handleEditClick = (list) => {
    setEditMailingList(list);
    goEdit();
  };

  const renderExportLink = (list) => {
    return (
      <>
        <a href={`/reporting/events/${event.slug}/emails/mailing_list/${list.gid}`} data-turbolinks="false">
          Export
        </a>
      </>
    );
  };

  const renderEditLink = (list) => {
    if (editEnabled()) {
      return (
        <>
          <span className="cursor-pointer" onClick={() => handleEditClick(list)}>
            Edit
          </span>
        </>
      );
    }
  };

  const renderArchiveLink = (list, arch) => {
    if (editEnabled()) {
      return (
        <>
          <span
            className="cursor-pointer"
            onClick={() => {
              archiveToggle(list.id, arch);
            }}
          >
            {archiveLabel(arch)}
          </span>
        </>
      );
    }
  };

  const renderUser = (user) => {
    if (!user) {
      return "(system); ";
    }

    return `${user.name_first} ${user.name_last}`;
  };

  const columns = (arch) => {
    return [
      {
        headerName: "List Name",
        flex: 1,
        field: "name"
      },
      {
        headerName: "Recipient Count",
        flex: 1,
        field: "recipient_count"
      },
      {
        headerName: "Created By",
        flex: 1,
        field: "created_by",
        renderCell: (params) => renderUser(params.row.user)
      },
      {
        headerName: "Last Modified",
        flex: 1,
        field: "modified_at",
        renderCell: (params) => formatDate(params.value, event.time_zone)
      },
      {
        headerName: "Actions",
        type: "actions",
        flex: 1,
        minWidth: 180,
        align: "right",
        field: "actions",
        getActions: (params) => [
          renderExportLink(params.row),
          renderEditLink(params.row),
          renderArchiveLink(params.row, arch)
        ]
      }
    ];
  };

  const renderMailingListTable = (filteredLists, arch = false) => {
    if (!fetched) {
      return <Loading />;
    }

    return (
      <div>
        <GrowlTable
          columns={columns(arch)}
          items={filteredLists}
          sortColumn="modified_at"
          sortDirection="desc"
          tableName={`${event.slug}-mailing-lists-${arch}`}
        />
      </div>
    );
  };

  const renderArchivedLists = () => {
    if (!showArchive) {
      return <></>;
    }

    return renderMailingListTable(
      lists.filter((l) => l.archived),
      true
    );
  };

  const renderNewListButton = () => {
    if (editEnabled()) {
      return renderCreateButton("Create New Mailing List", goNew);
    }
  };

  return (
    <div>
      <PageHeader text="Mailing Lists" />
      {renderNewListButton()}
      {renderMailingListTable(lists.filter((l) => !l.archived))}
      <div className="py-2">
        <UiToggle
          flag={showArchive}
          label="Archive"
          toggleFunc={() => {
            setShowArchive(!showArchive);
          }}
        />
        {renderArchivedLists()}
      </div>
    </div>
  );
};

export default CommunicationsEmailMailingLists;
