import React from "react";

import { FormControl, FormLabel, MenuItem, Select } from "@mui/material";
import { Controller } from "react-hook-form";

const WidgetEmailTemplateSelect = (props) => {
  const { control } = props;
  return (
    <div className="mb-4 mt-4">
      <div className="mb-4 w-full">
        <FormControl fullWidth>
          <FormLabel>Select email recipients to count</FormLabel>
          <Controller
            name="widget_config[email_recipients]"
            control={control}
            render={({ field: { value, onChange, ...field } }) => (
              <Select
                {...field}
                fullWidth
                onChange={onChange}
                value={value}
                variant="outlined"
                size="small"
                MenuProps={{ disableScrollLock: true }}
              >
                {[
                  {
                    id: "all",
                    name: "All"
                  },
                  {
                    id: "main_recipient",
                    name: "Main Recipient Only (Ignore CCs & BCCs)"
                  }
                ].map((template) => (
                  <MenuItem key={`recipient-${template.id}`} value={template.id}>
                    {template.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
      </div>
    </div>
  );
};

export default WidgetEmailTemplateSelect;
