import React, { useContext } from "react";

import Stack from "@mui/material/Stack";
import axios from "axios";
import { Formik, Form } from "formik";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import { alertError, alertHttpError } from "@shared/Alerts";
import SelectField from "@shared/forms/SelectField";
import { renderCancelButton, renderSubmitButton, renderTextField } from "@shared/FormUtils";

const CommunicationsEmailMailingListEntryForm = ({
  entry,
  listId,
  participants = [],
  reset = () => {
    alertError("not implemented");
  },
  update = () => {
    alertError("not implemented");
  } 
}) => {
  const { apiRoot } = useContext(EventContext).values;

  const isEdit = () => {
    return entry && entry.id;
  };

  const formConfig = (() => {
    if (isEdit()) {
      return {
        alert: "updated",
        formId: "sg-mgmt-form-entry-edit",
        formUrl: urljoin(apiRoot, `/communications/lists/${listId}/entries/${entry.id}`),
        method: "PATCH",
        title: "Update Mailing List Entry"
      };
    }
    return {
      alert: "added",
      formId: "sg-mgmt-form-entry-add",
      formUrl: urljoin(apiRoot, `/communications/lists/${listId}/add`),
      method: "POST",
      title: "Add Mailing List Entry"
    };
  })();

  const formInitialValues = () => {
    return {
      name_first: entry.name_first || "",
      name_last: entry.name_last || "",
      email: entry.email || "",
      cc_list: entry.cc_list || "",
      bcc_list: entry.bcc_list || "",
      custom_tag_1: entry.custom_tag_1 || "",
      custom_tag_2: entry.custom_tag_2 || "",
      custom_tag_3: entry.custom_tag_3 || "",
      custom_tag_4: entry.custom_tag_4 || "",
      custom_tag_5: entry.custom_tag_5 || ""
    };
  };

  const renderParticipantSelect = (setFieldValue) => {
    const options = participants.map((part) => ({
      label: `${part.name_first} ${part.name_last} (${part.email})`,
      value: part.id
    }));
    return (
      <div className="sg-mgmt-form-input-container">
        <label>Select participant (or begin typing values in below):</label>
        <SelectField
          classNamePrefix="sg-mgmt-form-input"
          options={options}
          name="participant-select"
          onChange={(selected) => {
            const participant = participants.find((p) => p.id === selected);
            if (participant) {
              setFieldValue("entry[name_first]", participant.name_first);
              setFieldValue("entry[name_last]", participant.name_last);
              setFieldValue("entry[email]", participant.email);
            }
          }}
        />
      </div>
    );
  };

  const renderEmailMailingListEntryForm = () => {
    return (
      <Formik
        initialValues={{
          entry: formInitialValues()
        }}
        onSubmit={(values, { setSubmitting }) => {
          // const form = document.getElementById(formConfig.formId);
          // const formData = new FormData(form);
          const token = document.querySelector("[name=csrf-token]").content;
          axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
          axios({
            url: formConfig.formUrl,
            method: formConfig.method,
            data: values
          })
            .then((response) => {
              if (response.data.error == null) {
                update(response.data.entry);
                reset();
              } else {
                // callbackFailure(response);
                alertError(response.data.error);
                setSubmitting(false);
              }
            })
            .catch((error) => {
              alertHttpError(error);
            });
        }}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form className="sg-mgmt-form" id={formConfig.formId}>
            <div className="sg-mgmt-form-container">
              <div className="sg-mgmt-form-section">
                <h2>Add Mailing List Entry</h2>
                <div className="sg-mgmt-form-row">
                  <div className="sg-mgmt-form-row-column">
                    {renderParticipantSelect(setFieldValue)}
                    {renderTextField("Email", "entry[email]")}
                    {renderTextField("First Name", "entry[name_first]")}
                    {renderTextField("Last Name", "entry[name_last]")}
                    {renderTextField("CC addresses (comma separated)", "entry[cc_list]")}
                    {renderTextField("BCC addresses (comma separated)", "entry[bcc_list]")}
                    {renderTextField("Custom Merge Tag 1 (*|CUSTOM1|*)", "entry[custom_tag_1]")}
                    {renderTextField("Custom Merge Tag 2 (*|CUSTOM2|*)", "entry[custom_tag_2]")}
                    {renderTextField("Custom Merge Tag 3 (*|CUSTOM3|*)", "entry[custom_tag_3]")}
                    {renderTextField("Custom Merge Tag 4 (*|CUSTOM4|*)", "entry[custom_tag_4]")}
                    {renderTextField("Custom Merge Tag 5 (*|CUSTOM5|*)", "entry[custom_tag_5]")}
                  </div>
                </div>
              </div>
            </div>
            <Stack direction="row" spacing={2}>
              {renderSubmitButton(formConfig.title, isSubmitting)}
              {renderCancelButton("Cancel", reset)}
            </Stack>
          </Form>
        )}
      </Formik>
    );
  };

  return <div>{renderEmailMailingListEntryForm()}</div>;
};

export default CommunicationsEmailMailingListEntryForm;
