import React from "react";
import Modal from "react-modal";
import EventSpeakersBlankSpeaker from "./EventSpeakersBlankSpeaker";
import EventSpeakersForm from "./EventSpeakersForm";

const EventSpeakersModal = ({
  modalVisible,
  resetModal,
  speaker = EventSpeakersBlankSpeaker,
  updateFunc
}) => {

  Modal.setAppElement("#root");

  const modalTitle = () => {
    if (speaker && speaker.id) {
      return `Speaker: ${speaker.name_first} ${speaker.name_last}`;
    }
    return "Add Speaker";
  };

  return (
    <Modal
      className="sg-mgmt-modal"
      overlayClassName="sg-mgmt-modal-overlay"
      isOpen={modalVisible}
      onRequestClose={resetModal}
      contentLabel="Add Speaker"
    >
      <div className="sg-mgmt-modal-frame">
        <div className="sg-mgmt-modal-title">{modalTitle()}</div>
        <div className="sg-mgmt-modal-content">
          <EventSpeakersForm
            reset={resetModal}
            speaker={speaker}
            update={updateFunc}
          />
        </div>
      </div>
    </Modal>
  );
};

export default EventSpeakersModal;
