import React from "react";

const MainIndexTile = props => {
  const { event } = props;

  const renderTileWithImage = () => {
    const tileStyle = {
      backgroundImage: `url(${event.tile_url})`,
      backgroundSize: "cover",
      backgroundPosition: "center center"
    };
    return <div className="sg-mgmt-tile" style={tileStyle} />;
  };

  const renderTilePlaceholder = () => (
    <div className="sg-mgmt-tile sg-mgmt-tile-placeholder bg-neutral-500" />
  );

  const renderTile = () => {
    return event.tile_url ? renderTileWithImage() : renderTilePlaceholder();
  };

  return (
    <div className="sg-mgmt-tile-container w-[200px]">
      <a href={event.path}>
        {renderTile()}
        <div className="break-words whitespace-pre-wrap text-center text-black text-base font-normal font-['Roboto'] leading-normal tracking-tight">{event.name}</div>
      </a>
    </div>
  );
};

export default MainIndexTile;
