import React, { useEffect, useState } from "react";
import axios from "axios";
import orderBy from "lodash/orderBy";
import urljoin from "url-join";
import MainIndexNewEventTile from "./MainIndexNewEventTile";
import MainIndexTile from "./MainIndexTile";
import MainMenu from "../MainMenu";
import ToggleArrow from "@shared/ToggleArrow";
import { Autocomplete, TextField } from "@mui/material";
const MainIndex = ({ rootUrl }) => {
  const [events, setEvents] = useState([]);
  const [fetched, setFetched] = useState(false);
  const [archivedOpen, setArchivedOpen] = useState(false);
  const [sortBy, setSortBy] = useState({ value: "date_end", label: "Date" });

  const sortOptions = [
    { value: "date_end", label: "Date" },
    { value: "name", label: "Alphabetical" }
  ]

  useEffect(() => {
    const fetchEvents = async () => {
      const result = await axios(urljoin(rootUrl, "/-/events"));
      setEvents(result.data);
      setFetched(true);
    };

    fetchEvents();
  }, [rootUrl]);

  const renderTiles = archived => {
    const filteredEvents = events.filter(e => e.archived === archived);
    let sortedEvents = []
    switch(sortBy.value) {
      case "name":
        sortedEvents = orderBy(
          filteredEvents,
          [event => (event.name||"").toLowerCase().trim()],
          ["asc"]
        );
        break;
      case "date_end":
      default:
        sortedEvents = orderBy(
          filteredEvents,
          ["date_end", "date_begin", "name"],
          ["desc", "desc", "asc"]
        );
        break;
    }
    return sortedEvents.map(e => <MainIndexTile event={e} key={e.gid} />);
  };

  const renderNewEventTile = () => {
    // TODO: Check user role and conditionally render
    return <MainIndexNewEventTile rootUrl={rootUrl} />;
  };

  toggleArchiveOpened = () => {
    setArchivedOpen(!archivedOpen);
  }

  const renderContent = () => {
    if (fetched) {
      return (
        <>
          <div className="sg-mgmt-index-header">
            Company Events
            <div className="float-right">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={sortOptions}
                sx={{ width: 300 }}
                size="small"
                value={sortBy}
                disableClearable={true}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                onChange={(event, newValue) => {
                  setSortBy(newValue);
                }}
                renderInput={(params) => <TextField {...params} label="Sort Events" />}
              />
            </div>
          </div>
          <div className="sg-mgmt-tile-section">
            {renderNewEventTile()}
            {renderTiles(false)}
          </div>
          <div onClick={toggleArchiveOpened} className="cursor-pointer sg-mgmt-index-header">
            <ToggleArrow
              open={archivedOpen}
              className="inline-block mr-2"
            />
            Archived Events
          </div>
          <div className="sg-mgmt-tile-section">
            {archivedOpen ? renderTiles(true) : ""}
          </div>
        </>
      );
    }
    return <h2>Loading</h2>;
  };

  return (
    <div className="sg-mgmt-content-frame">
      <MainMenu />
      <div className="sg-mgmt-index">{renderContent()}</div>
    </div>
  );
};

export default MainIndex;
