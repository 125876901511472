import React from "react";

import clsx from "clsx";

const Tabs = (props) => {
  const { tabs, activeTabId, classNames } = props;
  const renderTabs = () => {
    return tabs.map((tab) => renderTab(tab));
  };

  const isTabActive = (tab) => {
    return activeTabId == tab.id;
  };

  const renderTab = (tab) => {
    return (
      <a
        key={tab.id}
        href={tab.href}
        onClick={() => onClickTab(tab)}
        className={clsx(
          "relative mr-1 inline-block h-10 min-w-[174px] cursor-pointer rounded-tl-[3px] rounded-tr-[3px]",
          isTabActive(tab) ? "bg-white" : "bg-[#E1F5FE]"
        )}
      >
        <div className={clsx("p-2 text-center text-base text-ui-6", isTabActive(tab) ? "font-medium" : "font-normal")}>
          {tab.icon ? renderTabIcon(tab) : ""}
          {tab.name}
        </div>
      </a>
    );
  };

  const renderTabIcon = (tab) => {
    return <img className="w-18 h-18 -mt-1 mr-1 inline-block" src={tab.icon} alt={`${tab.name} tab icon`} />;
  };
  const onClickTab = (tab) => {
    if (tab.onClick) {
      tab.onClick();
    }
  };

  return (
    <div
      className={clsx(
        "no-scrollbar h-14 min-w-full overflow-scroll whitespace-nowrap bg-sky-200 pt-4",
        (classNames || {}).outerContainer
      )}
    >
      <div className={`mx-12 ${(classNames || {}).innerContainer}`}>{renderTabs()}</div>
    </div>
  );
};

export default Tabs;
