import React, { useContext, useState } from "react";
import axios from "axios";
import { Field, Formik, Form } from "formik";
import urljoin from "url-join";
import {
  renderRegexField,
  renderSubmitButton,
  renderTextField, 
  renderCancelButton
} from "@shared/FormUtils";
import Stack from '@mui/material/Stack';
import RadioField from "@shared/RadioField";
import SelectField from "@shared/SelectField";
import EventContext from "@event/EventContext";
import EventDetailsBlankField from "./EventDetailsBlankField";
import EventDetailsRegistrationFieldFormOptions from "./EventDetailsRegistrationFieldFormOptions";

const EventDetailsRegistrationFieldForm = ({
  callbackFailure = () => {},
  callbackSuccess = () => {},
  cancelButton = () => {},
  field
}) => {
  const { apiRoot } = useContext(EventContext).values;

  const isEdit = () => {
    return field && field.id;
  };

  const formConfig = (() => {
    if (isEdit()) {
      return {
        alert: "updated",
        formId: "sg-mgmt-form-token-edit",
        formUrl: urljoin(apiRoot, "/details/registration", `/${field.id}`),
        method: "PATCH",
        saveButton: "Update",
        title: "Edit Field"
      };
    }
    return {
      alert: "added",
      formId: "sg-mgmt-form-token-add",
      formUrl: urljoin(apiRoot, "/details/registration"),
      method: "POST",
      saveButton: "Create",
      title: "Create Field"
    };
  })();

  const renderTypeField = () => {
    const typeOptions = [
      { label: "Text", value: "text" },
      { label: "Dropdown", value: "dropdown" },
      { label: "Checkboxes", value: "checkboxes" }
    ];
    return <RadioField fieldName="field[field_format]" options={typeOptions} />;
  };

  // const renderOptionsField = format => {
  //   if (format === "text") {
  //     return <></>;
  //   }
  //   return (
  //     <div className="sg-mgmt-form-input-container">
  //       <label>Options (enter one per line):</label>
  //       <Field
  //         className="sg-mgmt-form-input"
  //         component="textarea"
  //         type="text"
  //         name="field[field_options]"
  //         autoComplete="off"
  //       />
  //     </div>
  //   );
  // };

  const renderOptionsFields = format => {
    if (format === "text") {
      return <></>;
    }
    return <EventDetailsRegistrationFieldFormOptions field={field}/>;
  };

  const renderSelectionMinMaxField = format => {
    if (format === "checkboxes") {
      const countOptions = [
        [0, 0],
        [1, 1],
        [2, 2],
        [3, 3],
        [4, 4],
        ["No Limit", 999]
      ];
      return (
        <>
          <div className="sg-mgmt-form-input-container sg-mgmt-form-input-container-reg-checkbox-selection">
            <label>Required selections:</label>
            <SelectField fieldName="field[selection_min]" options={countOptions} />
          </div>
          <div className="sg-mgmt-form-input-container sg-mgmt-form-input-container-reg-checkbox-selection">
            <label>Maximum selections:</label>
            <SelectField
              fieldName="field[selection_max]"
              autoComplete="off"
              options={countOptions}
            />
          </div>
        </>
      );
    }
    return <></>;
  };

  const formInitialValues = () => {
    if (field) {
      return {
        label: field.label || "",
        slug: field.slug || "",
        field_format: field.field_format || "text",
        field_options: field.options || [],
        event_field_options: field.event_field_options || [],
        sort_order: field.sort_order || 0,
        selection_min: field.selection_min || 1,
        selection_max: field.selection_max || 1
      };
    }
    return EventDetailsBlankField;
  };

  const renderForm = () => {
    return (
      <Formik
        initialValues={{
          field: formInitialValues()
        }}
        onSubmit={(values, { setSubmitting }) => {
          const form = document.getElementById(formConfig.formId);
          const formData = new FormData(form);
          const csrfToken = document.querySelector("[name=csrf-token]").content;
          axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;

          const slugRegex = new RegExp("^[0-9A-Za-z-]{3,32}$");

          if (!slugRegex.test(values.field.slug)) {
            alert(
              "Slug must have letters, numbers, and hyphens only, and must be between 3 and 32 characters."
            );
            setSubmitting(false);
            return;
          }

          axios({
            url: formConfig.formUrl,
            method: formConfig.method,
            data: formData
          }).then(response => {
            if (response.data.error === null) {
              callbackSuccess(response);
            } else {
              callbackFailure(response);
              setSubmitting(false);
            }
          });
        }}
      >
        {({ values, isSubmitting }) => (
          <Form className="sg-mgmt-form" id={formConfig.formId}>
            <div className="sg-mgmt-form-container">
              <div className="sg-mgmt-form-row">
                {renderTextField("Field label", "field[label]")}
              </div>
              <div className="sg-mgmt-form-row">
                {renderRegexField(
                  "Slug (lowercase letters, numbers, hyphens only",
                  "field[slug]",
                  RegExp(/^[0-9a-z-]*$/)
                )}
              </div>
              <div className="sg-mgmt-form-row">{renderTypeField()}</div>
              {renderOptionsFields(values.field.field_format)}
              <div className="sg-mgmt-form-row">
                {renderSelectionMinMaxField(values.field.field_format)}
              </div>
            </div>
            <Stack direction="row" spacing={2}>
              {renderSubmitButton(formConfig.saveButton, isSubmitting)}
              {renderCancelButton("Cancel", cancelButton)}
            </Stack>
          </Form>
        )}
      </Formik>
    );
  };

  return <>{renderForm()}</>;
};

export default EventDetailsRegistrationFieldForm;
