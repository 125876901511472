import React, { useContext, useState } from "react";
import axios from "axios";
import clsx from "clsx";
import { parseISO } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import { Field, Formik, Form } from "formik";
import range from "lodash/range";
import urljoin from "url-join";
import { alertError, alertHttpError, alertSuccess } from "@shared/Alerts";
import DateTimePickerField from "@shared/DateTimePickerField";
import IntegerField from "@shared/IntegerField";
import SelectField from "@shared/SelectField";
import EventContext from "@event/EventContext";
import EventMeetings1On1BlankMeeting from "./EventMeetings1On1BlankMeeting";
import EventMeetings1On1Context from "../EventMeetings1On1Context";
import { renderCancelButton, renderSubmitButton } from "@shared/FormUtils";
import { Stack } from "@mui/material";

const EventMeetings1On1Form = ({
  callbackFailure = () => {},
  callbackSuccess = () => {},
  cancel,
  hosts,
  participants,
  updateHosts
}) => {
  const { apiRoot, event } = useContext(EventContext).values;
  const { meeting } = useContext(EventMeetings1On1Context);

  const [hostFields, setHostFields] = useState(1);

  const isEdit = () => {
    return meeting && meeting.id;
  };

  const addHostField = () => {
    setHostFields(hostFields + 1);
  };

  const formConfig = (() => {
    if (isEdit()) {
      return {
        alert: "updated",
        formId: "sg-mgmt-form-meeting-edit",
        formUrl: urljoin(apiRoot, "/meeting1on1s", `/${meeting.id}`),
        method: "PATCH",
        saveButton: "Save",
        title: "Edit Meeting"
      };
    }
    return {
      alert: "added",
      formId: "sg-mgmt-form-meeting-add",
      formUrl: urljoin(apiRoot, "/meeting1on1s"),
      method: "POST",
      saveButton: "Continue",
      title: "Add Meeting"
    };
  })();

  const dateTimeInitialValue = () => {
    if (meeting.date_and_time_local) {
      return parseISO(meeting.date_and_time_local);
    }
    if (event.date_begin) {
      return parseISO(event.date_begin);
    }
    return new Date();
  };

  // const renderRequiredField = (label, field, formatClasses = []) => (
  //   <div className={clsx("sg-mgmt-form-input-container", formatClasses)}>
  //     <label>{label}</label>
  //     <Field
  //       className="sg-mgmt-form-input"
  //       type="text"
  //       name={`meeting[${field}]`}
  //       autoComplete="off"
  //     />
  //   </div>
  // );

  const renderDataField = (label, field, formatClasses = []) => (
    <div className={clsx("sg-mgmt-form-input-container", formatClasses)}>
      <label>{label}</label>
      <Field
        className="sg-mgmt-form-input"
        type="text"
        name={`meeting[data][${field}]`}
        autoComplete="off"
      />
    </div>
  );

  // TODO: Make these dates reflect the event date range
  const renderDateTimeField = (label, field, formatClasses = []) => (
    <div className={clsx("sg-mgmt-form-input-container", formatClasses)}>
      <label>{label}</label>
      <DateTimePickerField
        className={clsx("sg-mgmt-form-input", "sg-mgmt-form-input-time")}
        selected={new Date(`${event.date_begin}T09:00:00`)}
        minDate={new Date(`${event.date_begin}T00:00:00`)}
        maxDate={new Date(`${event.date_end}T00:00:00`)}
        minTime={new Date(2000, 1, 1, 8, 0)}
        maxTime={new Date(2000, 1, 1, 19, 30)}
        timeIntervals={60}
        name={`meeting[${field}]`}
        autoComplete="off"
      />
    </div>
  );

  const renderIntegerField = (label, field, formatClasses = []) => (
    <div className={clsx("sg-mgmt-form-input-container", formatClasses)}>
      <label>{label}</label>
      <IntegerField
        className="sg-mgmt-form-input"
        name={`meeting[${field}]`}
        autoComplete="off"
      />
    </div>
  );

  const renderMeetingType = () => {
    const options = ["General (AR/PR)", "Prospect", "Customer", "Partner"];
    return (
      <div className="sg-mgmt-form-input-container">
        <label>Meeting Type</label>
        <SelectField fieldName="meeting[meeting_type]" options={options} />
      </div>
    );
  };

  const renderUseCase = () => {
    const options = [
      "DevSecOps for Kubernetes",
      "Tired Analytics/Credit Based Licensing",
      "Security Intelligence",
      "Solution (Cloud SIEM, SOC Analytics & Automation)",
      "Observability",
      "Other"
    ];
    return (
      <div className="sg-mgmt-form-input-container">
        <label>Meeting Type</label>
        <SelectField fieldName="meeting[data][use_case]" options={options} />
      </div>
    );
  };

  const renderHost = idx => {
    const options = hosts.map(h => ({
      label: `${h.name_first} ${h.name_last}`,
      value: h.id
    }));
    return (
      <div className="sg-mgmt-form-input-container" key={`host-field-${idx}`}>
        <label>Executive:</label>
        <SelectField
          fieldName={`meeting[meeting_hosts][${idx}]`}
          options={options}
        />
      </div>
    );
  };

  const renderHosts = () => {
    return range(1, hostFields + 1).map(i => renderHost(i));
  };

  const renderAttendee = () => {
    const options = participants.map(h => ({
      label: `${h.name_first} ${h.name_last}`,
      value: h.id
    }));
    return (
      <div className="sg-mgmt-form-input-container">
        <label>Attendee:</label>
        <SelectField fieldName="meeting[participants][1]" options={options} />
      </div>
    );
  };

  const renderAddHost = () => {
    return <div onClick={addHostField}>+ Add Executive</div>;
  };

  const formInitialValues = () => {
    if (isEdit()) {
      return {
        meeting_url: meeting.meeting_url,
        date_and_time: dateTimeInitialValue(),
        length_minutes: 45,
        meeting_hosts: [],
        participants: [],
        data: {
          objective_1: meeting.data.objective_1,
          objective_2: meeting.data.objective_2,
          objective_3: meeting.data.objective_3,
          outcome: meeting.data.outcome,
          use_case: meeting.data.use_case,
          attendee_details: {},
          company_overview: meeting.data.company_overview,
          stage_account: meeting.data.stage_account,
          potential_revenue: meeting.data.potential_revenue,
          close_date: meeting.data.close_date,
          notes: meeting.data.notes
        }
      };
    }
    const initialVals = EventMeetings1On1BlankMeeting;
    initialVals.date_and_time = parseISO(`${event.date_begin}T09:00:00`);
    return EventMeetings1On1BlankMeeting;
  };

  const renderForm = () => (
    <Formik
      initialValues={{
        meeting: formInitialValues()
      }}
      onSubmit={(values, { setSubmitting }) => {
        const form = document.getElementById(formConfig.formId);
        const formData = new FormData(form);

        // convert times to UTC for submission to back-end
        formData.set(
          "meeting[date_and_time]",
          zonedTimeToUtc(values.meeting.date_and_time, event.time_zone)
        );

        const token = document.querySelector("[name=csrf-token]").content;
        axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
        axios({
          url: formConfig.formUrl,
          method: formConfig.method,
          data: formData
        })
          .then(response => {
            if (response.data.error === null) {
              updateHosts(response.data.hosts);
              callbackSuccess();
              alertSuccess("Meeting created");
            } else {
              callbackFailure(response);
              alertError(response.data.error);
              setSubmitting(false);
            }
          })
          .catch(error => {
            alertHttpError(error);
          });
      }}
    >
      {({ isSubmitting }) => (
        <Form className="sg-mgmt-form" id={formConfig.formId}>
          <div className="sg-mgmt-form-container">
            <div className="sg-mgmt-form-section">
              <h2>Meeting Details</h2>
              <div className="sg-mgmt-form-row">
                <div className="sg-mgmt-form-row-column">
                  {renderMeetingType()}
                  {renderDataField("Objective 1", "objective_1")}
                  {renderDataField("Objective 2", "objective_2")}
                  {renderDataField("Objective 3", "objective_3")}
                  {renderDataField("Desired Outcome", "outcome")}
                  {renderHosts()}
                  {renderAddHost()}
                  {renderAttendee()}
                </div>
                <div className="sg-mgmt-form-row-column">
                  {renderUseCase()}
                  {renderDataField("Company Overview", "company_overview")}
                  {renderDataField("Stage of Account", "stage_account")}
                  {renderDataField("Potential Revenue", "potential_revenue")}
                  {renderDataField("Close Date", "close_date")}
                  {renderDataField("Notes/Comments", "notes")}
                </div>
              </div>
            </div>
            <div className="sg-mgmt-form-section">
              <h2>Meeting Date and Time</h2>
              <div className="sg-mgmt-form-row">
                {renderDateTimeField(
                  `Date and Start Time (timezone: ${event.time_zone})`,
                  "date_and_time",
                  "sg-mgmt-form-input-date-time"
                )}
              </div>
            </div>
          </div>
          <Stack direction="row" spacing={2}>
            {renderSubmitButton(formConfig.saveButton, isSubmitting)}
            {renderCancelButton("Cancel", cancel)}
          </Stack>
        </Form>
      )}
    </Formik>
  );

  return <>{renderForm()}</>;
};


export default EventMeetings1On1Form;
