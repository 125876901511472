import React, { useContext, useEffect, useState } from "react";
import { parseISO } from "date-fns";
import { format, utcToZonedTime } from "date-fns-tz";
import { useConfirm } from "material-ui-confirm";
import Modal from "react-modal";
import axios from "axios";
import urljoin from "url-join";
import { alertHttpError } from "@shared/Alerts";
import Loading from "@shared/Loading";
import EventContext from "@event/EventContext";
import { Stack } from "@mui/material";
import {
  renderCancelButton,
  renderDeleteButton,
  renderButton,
  renderSubmitButton,
} from "@shared/FormUtils";
const EventMeetings1On1ModalView = ({
  editMeeting,
  meetingId = 0,
  modalVisible,
  removeMeeting,
  resetModal
}) => {
  const { apiRoot } = useContext(EventContext).values;
  const [meeting, setMeeting] = useState(null);
  const [fetched, setFetched] = useState(false);
  const confirm = useConfirm();

  Modal.setAppElement("#root");

  useEffect(() => {
    const fetchMeeting = async () => {
      try {
        const result = await axios(
          urljoin(apiRoot, "/meetings", `/${meetingId}`)
        );
        setMeeting(result.data.meeting);
        setFetched(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    if (meetingId > 0) {
      fetchMeeting();
    }
  }, [meetingId]);

  const renderMeetingHosts = () => {
    return meeting.meeting_hosts.map(host => (
      <div key={host.gid}>
        Executive: {`${host.name_first} ${host.name_last}`} ({`${host.email}`})
      </div>
    ));
  };

  const renderMeetingAttendees = () => {
    return meeting.event_participants.map(ep => (
      <div key={ep.gid}>
        Attendee: {`${ep.name_first} ${ep.name_last}`} ({`${ep.email}`})
      </div>
    ));
  };

  /* eslint-disable dot-notation */
  const renderMeetingScheduler = () => {
    if (meeting.data["scheduler_name_first"]) {
      return (
        <div>
          Scheduler:{" "}
          {`${meeting.data["scheduler_name_first"]} ${meeting.data["scheduler_name_last"]} (${meeting.data["scheduler_email"]})`}
        </div>
      );
    }
    return <></>;
  };
  /* eslint-enable dot-notation */

  const renderCloseButton = () => (
    renderCancelButton("Done", resetModal)
  );

  const renderEditButton = () => (
    renderButton("Edit", ()=>{editMeeting(meetingId); resetModal()})
  );

  const confirmDelete = () => {
    confirm({
      title: "Confirm delete",
      description:
        "Are you sure you want to delete this meeting? This cannot be undone!",
    }).then(() => {
      removeMeeting(meetingId);
      resetModal();
    }).catch(err => {
      alertError(err);
    })
  };

  const renderZoomCreated = () => {
    return (
      <>
        <div>
          Status: <span className="sg-mgmt-1on1-zoom-created">Created</span>
        </div>
        <div>
          Guest URL:{" "}
          <a
            className="sg-mgmt-link sg-mgmt-1on1-view-link-small"
            href={meeting.data.zoom.join_url}
          >
            {meeting.data.zoom.join_url}
          </a>
        </div>
        <div>
          Host URL:{" "}
          <a
            className="sg-mgmt-link sg-mgmt-1on1-view-link-small"
            href={meeting.data.zoom.start_url}
          >
            {meeting.data.zoom.start_url}
          </a>
        </div>
        <div>Password: {meeting.data.zoom.password}</div>
      </>
    );
  };

  const renderZoomError = () => {
    return (
      <>
        <div>
          Status: <span className="sg-mgmt-1on1-zoom-failed">Error</span>
        </div>
        <div>Error {meeting.data.zoom_failure}</div>
      </>
    );
  };

  const renderZoomNotCreated = () => {
    return (
      <>
        <div>Status: Pending</div>
      </>
    );
  };

  const renderLocation = () => {
    if (meeting.data.location) {
      return <div>Location: {meeting.data.location}</div>;
    }
    return <></>;
  };

  const renderZoom = () => {
    if (meeting.data.zoom) {
      return renderZoomCreated();
    }
    if (meeting.data.zoom_failure) {
      return renderZoomError();
    }
    return renderZoomNotCreated();
  };

  const renderMeeting = () => {
    if (fetched) {
      const parsedDatetime = utcToZonedTime(
        parseISO(meeting.date_and_time),
        "America/Los_Angeles"
      );
      return (
        <div>
          <div className="sg-mgmt-1on1-view-section">
            <h2>Meeting Date and Time:</h2>
            <div>
              {format(parsedDatetime, "EEEE, LLLL do", {
                timeZone: "America/Los_Angeles"
              })}
            </div>
            <div>
              {format(parsedDatetime, "h:mm aaaa", {
                timeZone: "America/Los_Angeles"
              })}{" "}
              PST
            </div>
          </div>
          <div className="sg-mgmt-1on1-view-section">
            <h2>Meeting Participants:</h2>
            {renderMeetingHosts()}
            {renderMeetingAttendees()}
            {renderMeetingScheduler()}
          </div>
          <div className="sg-mgmt-1on1-view-section">
            <h2>Zoom Details:</h2>
            {renderZoom()}
          </div>
          <div className="sg-mgmt-1on1-view-section">
            <h2>Meeting Details</h2>
            {renderLocation()}
            <div>Meeting type: {meeting.meeting_type}</div>
            <div>Objective #1: {meeting.data.objective_1}</div>
            <div>Objective #2: {meeting.data.objective_2}</div>
            <div>Objective #3: {meeting.data.objective_3}</div>
            <div>Desired Outcome: {meeting.data.outcome}</div>
            <div>Use Case: {meeting.data.use_case}</div>
            <div>Company Overview: {meeting.data.company_overview}</div>
            <div>Stage of Account: {meeting.data.stage_account}</div>
            <div>Potential Revenue: {meeting.data.potential_revenue}</div>
            <div>Close Date: {meeting.data.close_date}</div>
            <div>Notes/Comments: {meeting.data.notes}</div>
          </div>
        </div>
      );
    }
    return <Loading />;
  };

  return (
    <Modal
      className="sg-mgmt-modal"
      contentLabel="Add Meeting"
      isOpen={modalVisible}
      overlayClassName="sg-mgmt-modal-overlay"
      onRequestClose={resetModal}
    >
      <div className="sg-mgmt-modal-frame">
        <div className="sg-mgmt-modal-title">View Meeting</div>
        <div className="sg-mgmt-modal-content">
          {renderMeeting()}
          <Stack direction="row" spacing={2}>
            {renderCloseButton()}
            {renderEditButton()}
            {renderDeleteButton("Delete", confirmDelete)}
          </Stack>
        </div>
      </div>
    </Modal>
  );
};

export default EventMeetings1On1ModalView;
