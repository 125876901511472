import React, { useContext, useState } from "react";

import compact from "lodash/compact";

import EventUserContext from "@event/EventUserContext";
import { renderCreateButton } from "@shared/FormUtils";
import Loading from "@shared/Loading";

import SessionsBlankVenue from "./SessionsBlankVenue";
import SessionsVenueModal from "./SessionsVenueModal";

const SessionsVenuesIndex = props => {
  const {
    addVenue,
    fetched,
    venues,
    goEdit,
    goRooms,
    setEditVenueId,
  } = props;
  const [modalAddVisible, setModalAddVisible] = useState(false);
  const { user } = useContext(EventUserContext);

  const modalAddReset = () => {
    setModalAddVisible(false);
  };

  const editEnabled = () => {
    if (user.role === "basic" && !user.permission.sessions_edit) {
      return false;
    }
    return true;
  };

  const renderVenueTable = () => {
    if (fetched) {
      return (
        <div className="my-2">
          <table className="w-full table-fixed">
            <thead>
              <tr>
                <th className="w-1/6 bg-ui-3 px-4 py-2 text-left text-white">
                  Venue Name
                </th>
                <th className="w-1/6 bg-ui-3 px-4 py-2 text-left text-white">
                  Address
                </th>
                <th className="w-1/6 bg-ui-3 px-4 py-2 text-left text-white">
                  Rooms
                </th>
                <th className="w-1/6 bg-ui-3 px-4 py-2 text-right text-white">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {venues.map(venue => (
                <tr key={venue.gid}>
                  <td className="border-t px-4 py-2 font-bold">{venue.name}</td>
                  <td className="border-t px-4 py-2">
                    {venue.address_street_1}
                    <br />
                    {compact([
                      venue.address_city,
                      venue.address_state,
                      venue.address_country
                    ])
                      .filter(Boolean)
                      .join(", ")}
                  </td>
                  <td className="border-t px-4 py-2">{venue.rooms.length}</td>
                  <td className="border-t px-4 py-2 text-right">
                    {editEnabled() ? (
                      <>
                        <span
                          className="sg-mgmt-link"
                          onClick={() => {
                            setEditVenueId(venue.id);
                            goRooms();
                          }}
                        >
                          Manage Rooms
                        </span>
                        &nbsp;|&nbsp;
                        <span
                          className="sg-mgmt-link"
                          onClick={() => {
                            setEditVenueId(venue.id);
                            goEdit();
                          }}
                        >
                          Edit Venue
                        </span>
                      </>
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }
    return <Loading />;
  };

  const renderNewVenueButton = () => {
    if (editEnabled()) {
      return renderCreateButton("Create New Venue", () => {
        setModalAddVisible(true);
      });
    }
  };

  return (
    <div>
      {renderNewVenueButton()}
      {renderVenueTable()}
      <SessionsVenueModal
        venue={SessionsBlankVenue}
        modalVisible={modalAddVisible}
        resetModal={modalAddReset}
        title="Create Venue"
        updateFunc={addVenue}
      />
    </div>
  );
};


export default SessionsVenuesIndex;
