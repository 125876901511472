import React, { useContext } from "react";

import Stack from "@mui/material/Stack";
import axios from "axios";
import { Formik, Form } from "formik";

import EventContext from "@event/EventContext";
import { alertHttpError, alertSuccess } from "@shared/Alerts";
import FormikObserver from "@shared/FormikObserver";
import InputSlugGenerator from "@shared/forms/InputSlugGenerator";
import {
  renderCancelButton,
  renderSubmitButton,
  renderButton,
  renderTextField,
  renderRegexField,
  renderTextAreaField
} from "@shared/FormUtils";

import { previewById } from "./utils/FormPreviewUtils";
import FormsFormContentDropdown from "./utils/FormsFormContentDropdown";

const FormsFormsGeneralInfoForm = ({
  setForm,
  setDirty,
  formConfig,
  form,
  cancel,
  callbackSuccess = () => {},
  resetEditingTime,
  callbackFailure = () => {}
}) => {
  const { apiRoot } = useContext(EventContext).values;

  const formInitialValues = () => {
    let initial_values = {
      css: form.css || "",
      data: form.data || {},
      form_id: form.form_id || "",
      form_type: form.form_type,
      name: form.name,
      status: form.status
    };
    return initial_values;
  };

  const renderForm = (formikProps) => {
    return (
      <div className="sg-mgmt-form-container">
        <div className="sg-mgmt-form-section">
          <div className="flex">
            <div className="mr-4 w-1/2">{renderTextField("Form Name", "form[name]", [], false, true)}</div>
            <div className="mr-4 w-1/2">
              {renderRegexField("Form ID", "form[form_id]", RegExp("^[0-9A-Za-z_-]{0,32}$"), [], {
                fieldProperties: {
                  InputProps: {
                    endAdornment: formikProps.values.form.form_id ? (
                      ""
                    ) : (
                        <InputSlugGenerator
                          formikProps={formikProps}
                          name={`form[form_id`}
                          associatedValue={formikProps.values.form.name}
                          maxSlugLength={30}
                        />
                      )
                  }
                }
              })}
            </div>
          </div>
          <FormsFormContentDropdown header="Advanced Settings" defaultOpen={false}>
            <div className="flex">
              <div className="mr-4 w-full">
                {renderTextAreaField("Custom Styles (CSS)", "form[css]", [], false, true)}
              </div>
            </div>

            <div className="flex">
              <div className="mr-4 w-full">
                {renderTextField("Custom Preview Link", "form[data][custom_preview_link]")}
              </div>
            </div>

            <div className="flex">
              <div className="mr-4 w-full">
                {renderTextField(
                  "Custom Preview Attendee Email (optional)",
                  "form[data][custom_preview_attendee_email]"
                )}
              </div>
            </div>

            <div className="flex">
              <div className="mr-4 mb-2 w-full">
                Form GID: {form.gid}
              </div>
            </div>
          </FormsFormContentDropdown>
        </div>
      </div>
    );
  };

  const formValidation = (values) => {
    const errors = {};

    if (!values.form.form_id) {
      alert("You must enter a unique form id.");
      errors.form = errors.form || {};
      errors.form.form_id = "Required";
    }
    if (!values.form.name) {
      alert("You must enter a unique form id.");
      errors.form = errors.form || {};
      errors.form.name = "Required";
    }

    return errors;
  };

  const renderButtons = (formikProps) => {
    const { isSubmitting } = formikProps;
    return (
      <Stack direction="row" spacing={2}>
        {renderSubmitButton(formConfig.saveButton, isSubmitting)}
        {form && form.data && form.data.custom_preview_link
          ? renderButton("Preview", (e) => previewPage(formikProps))
          : ""}
        {renderCancelButton("Cancel", cancel)}
      </Stack>
    );
  };

  const previewPage = (formikProps) => {
    const { values } = formikProps;
    previewById(form.id, apiRoot, {
      attendee_email: (values.form.data || {}).custom_preview_attendee_email,
      custom_preview_link: (values.form.data || {}).custom_preview_link
    });
  };

  const formOnChange = (formikProps) => {
    if (formikProps.dirty) {
      setDirty(true);
    }
  };

  const renderFormik = () => (
    <Formik
      enableReinitialize
      initialValues={{
        form: formInitialValues()
      }}
      formValidation={formValidation}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        const token = document.querySelector("[name=csrf-token]").content;
        axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
        axios({
          url: formConfig.formUrl,
          method: formConfig.method,
          data: values
        })
          .then((response) => {
            if (response.data.error === null) {
              callbackSuccess ? callbackSuccess(response) : () => { };
              alertSuccess("Form saved");
              setForm(response.data.form);
              resetEditingTime(new Date(response.data.form.updated_at));
              setSubmitting(false);

              setDirty(false);
            } else {
              callbackFailure ? callbackFailure(response) : () => { };

              alertError(`Unable to save form: ${response.data.error}`);
              setSubmitting(false);
            }
          })
          .catch((error) => {
            alertHttpError(error);
          });
      }}
    >
      {(formikProps) => (
        <Form className="sg-mgmt-form" id={formConfig.formId}>
          <FormikObserver formikProps={formikProps} onChange={formOnChange} />
          {renderForm(formikProps)}
          {renderButtons(formikProps)}
        </Form>
      )}
    </Formik>
  );

  return <div className="max-w-screen-lg">{renderFormik()}</div>;
};

export default FormsFormsGeneralInfoForm;
