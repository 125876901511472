import React from "react";

import { Stack } from "@mui/material";

import { renderCancelButton, renderButton } from "@shared/FormUtils";
import PageHeader from "@shared/PageHeader";

const CustomReportType = ({
  goBack,
  reportType = { id: null },
  reportTypes,
  setReportType,
  submit
}) => {

  const renderReportType = () => {
    const renderedTypes = reportTypes.map((type) => (
      <div
        key={`report-type-${type.id}`}
        className={`report-select-key-type ${type.id === reportType.id ? "report-select-key-type-selected" : ""}`}
        onClick={() => setReportType(type)}
      >
        <div className="report-select-key-type-title">{type.label}</div>
      </div>
    ));
    return <div className="report-select-key-type-wrapper">{renderedTypes}</div>;
  };

  const renderSelectReportType = () => {
    const objects = reportTypes.map((object) => {
      const obj = { value: object, label: object };
      return obj;
    });
    return (
      <div>
        <div className="report-select-keys">
          <label>Select a report type</label>
        </div>
        {renderReportType(objects)}
      </div>
    );
  };

  const cancelButton = () => {
    goBack();
  };

  const renderButtons = () => {
    const disabled = reportType.id === null;
    return (
      <div className="report-select-keys report-select-keys-divider">
        <Stack spacing={2} direction="row">
          {renderButton("Continue", submit, {
            disabled: disabled,
            color: "secondary"
          })}
          {renderCancelButton("Cancel", cancelButton, {
            color: "secondary"
          })}
        </Stack>
      </div>
    );
  };

  return (
    <div className="report-tool-form-wrapper" style={{ margin: "0px" }}>
      <div>
        <PageHeader subHeader text="New Report" />
      </div>
      <div className="mt-2">
        {renderSelectReportType()}
        {renderButtons()}
      </div>
    </div>
  );
};

export default CustomReportType;
