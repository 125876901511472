import React from "react";
import EventDetailsRegistrationSectionPreview from "./EventDetailsRegistrationSectionPreview";

const EventDetailsRegistrationPreview = props => {
  const { fields, addField, removeField, updateField } = props;
  // const [sectionCount, setSectionCount] = useState(1);

  const renderBody = () => (
    <>
      <div className="sg-mgmt-details-section">
        <h2>General Information</h2>
        <div>First Name</div>
        <div>Last Name</div>
        <div>Company</div>
        <div>Job Title</div>
        <div>Email</div>
      </div>
      <EventDetailsRegistrationSectionPreview
        fields={fields}
        section={2}
        addField={addField}
        removeField={removeField}
        updateField={updateField}
      />
    </>
  );

  return (
    <div>
      <div className="sg-mgmt-details-section">
        <p>
          Here is the information that will be collected in the registration
          form.
        </p>
      </div>
      {renderBody()}
    </div>
  );
};

export default EventDetailsRegistrationPreview;
