import React, { useContext, useEffect, useState } from "react";

import axios from "axios";
import sortBy from "lodash/sortBy";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import EventUserContext from "@event/EventUserContext";
import { alertHttpError } from "@shared/Alerts";
import { renderCreateButton, renderCancelButton } from "@shared/FormUtils";
import Loading from "@shared/Loading";

import SessionsBlankRoom from "./SessionsBlankRoom";
import SessionsVenueRoomModal from "./SessionsVenueRoomModal";

const SessionsVenueRooms = props => {
  const { config, goIndex, venueId, setEditVenueId } = props;
  const { apiRoot } = useContext(EventContext).values;
  const [editRoom, setEditRoom] = useState(SessionsBlankRoom);
  const [modalAddVisible, setModalAddVisible] = useState(false);
  const [modalEditVisible, setModalEditVisible] = useState(false);
  const [venue, setVenue] = useState({});
  const [manageRoom, setManageRoom] = useState(SessionsBlankRoom);
  const [fetched, setFetched] = useState(false);
  const { user } = useContext(EventUserContext);

  useEffect(() => {
    const fetchVenue = async () => {
      try {
        const result = await axios(
          urljoin(apiRoot, "/venues", `/${venueId}`)
        );
        setVenue(result.data.venue);
        setFetched(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchVenue();
  }, [apiRoot, venue.id]);

  const sortRooms = toSort => sortBy(toSort, ["name"]);

  const modalAddReset = () => {
    setModalAddVisible(false);
  };

  const modalEditReset = () => {
    setModalEditVisible(false);
  };

  const addRoom = room => {
    const newRooms = venue.rooms.concat(room);
    const newVenue = venue;
    venue.rooms = newRooms;
    setVenue(newVenue);
  };

  const updateRoom = room => {
    const newRooms = venue.rooms.map(rm =>
      rm.gid === room.gid ? room : rm
    );
    const newVenue = venue;
    venue.rooms = newRooms;
    setVenue(newVenue);
  };

  const editEnabled = () => {
    if (user.role === "basic") {
      return false;
    }
    return true;
  };

  const renderRooms = () => {
    if (fetched) {
      return (
        <div className="mb-2">
          <table className="w-full table-fixed">
            <thead>
              <tr>
                <th className="py-2 px-4 w-1/6 text-left text-white bg-ui-3">
                  Room Name
                </th>
                <th className="py-2 px-4 w-1/6 text-left text-white bg-ui-3">
                  Location
                </th>
                <th className="py-2 px-4 w-1/6 text-right text-white bg-ui-3">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {venue.rooms.map(room => (
                <tr key={room.gid}>
                  <td className="py-2 px-4 font-bold border-t">{room.name}</td>
                  <td className="py-2 px-4 font-bold border-t">{room.location}</td>
                  <td className="py-2 px-4 text-right border-t">
                    <span
                      className="sg-mgmt-link"
                      onClick={() => {
                        setEditRoom(room);
                        setModalEditVisible(true);
                      }}
                    >
                      Edit Room
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }
    return <Loading />;
  };

  const renderNewRoomButton = () => {
    return renderCreateButton(
      "Create New Room",
      ()=> {
        setModalAddVisible(true);
      },
      false,
      {
        className: "mr-4"
      }
    )
  };

  const renderBackButton = () => {
    return (
      renderCancelButton("Back", ()=> {
        setEditVenueId(null);
        goIndex();
      })
    );
  };

  return (
    <div>
      <h1>{`Rooms for ${venue.name}`}</h1>
      {renderRooms()}
      <SessionsVenueRoomModal
        venue={venue}
        modalVisible={modalAddVisible}
        resetModal={modalAddReset}
        room={SessionsBlankRoom}
        title="Create Room"
        updateFunc={addRoom}
      />
      <SessionsVenueRoomModal
        venue={venue}
        modalVisible={modalEditVisible}
        resetModal={modalEditReset}
        room={editRoom}
        title="Update Room"
        updateFunc={updateRoom}
      />
      {renderNewRoomButton()}
      {renderBackButton()}
    </div>
  );
};

export default SessionsVenueRooms;
