import React, { useContext, useState } from "react";
import axios from "axios";
import { Field, Formik, Form } from "formik";
import urljoin from "url-join";
import {
  renderSubmitButton,
  renderTextField,
  renderCancelButton
} from "@shared/FormUtils";
import Stack from "@mui/material/Stack";
import EventContext from "@event/EventContext";
import HousingConfigurationBlankBillingInstruction from "./HousingConfigurationBlankBillingInstruction";

const HousingConfigurationBillingInstructionsForm = ({
  callbackFailure = () => {},
  callbackSuccess = () => {},
  cancelButton = () => {},
  instruction
}) => {
  const { apiRoot } = useContext(EventContext).values;

  const isEdit = () => {
    return instruction && instruction.id;
  };

  const formConfig = (() => {
    if (isEdit()) {
      return {
        alert: "updated",
        formId: "sg-mgmt-form-token-edit",
        formUrl: urljoin(apiRoot, "/housing/billing_instructions", `/${instruction.id}`),
        method: "PATCH",
        saveButton: "Update",
        title: "Edit Field"
      };
    }
    return {
      alert: "added",
      formId: "sg-mgmt-form-token-add",
      formUrl: urljoin(apiRoot, "/housing/billing_instructions"),
      method: "POST",
      saveButton: "Create",
      title: "Create Instructionk"
    };
  })();

  const formInitialValues = () => {
    if (isEdit()) {
      return {
        instruction_text: instruction.instruction_text || "",
        sort_order: instruction.sort_order || 0,
      };
    }
    return HousingConfigurationBlankBillingInstruction;
  };

  const renderForm = () => {
    return (
      <Formik
        initialValues={{
          instruction: formInitialValues()
        }}
        onSubmit={(values, { setSubmitting }) => {
          const form = document.getElementById(formConfig.formId);
          const formData = new FormData(form);
          const csrfToken = document.querySelector("[name=csrf-token]").content;
          axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;

          axios({
            url: formConfig.formUrl,
            method: formConfig.method,
            data: formData
          }).then(response => {
            if (response.data.error === null) {
              callbackSuccess(response);
            } else {
              callbackFailure(response);
              setSubmitting(false);
            }
          });
        }}
      >
        {({ values, isSubmitting }) => (
          <Form className="sg-mgmt-form" id={formConfig.formId}>
            <div className="sg-mgmt-form-container">
              <div className="sg-mgmt-form-row">
                {renderTextField("Instruction Text", "instruction[instruction_text]")}
              </div>
            </div>
            <Stack direction="row" spacing={2}>
              {renderSubmitButton(formConfig.saveButton, isSubmitting)}
              {renderCancelButton("Cancel", cancelButton)}
            </Stack>
          </Form>
        )}
      </Formik>
    );
  };

  return <>{renderForm()}</>;
};

export default HousingConfigurationBillingInstructionsForm;
