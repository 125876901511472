import React, { useContext, useState } from "react";
import axios from "axios";
import { Field, Formik } from "formik";
import urljoin from "url-join";
import { alertError, alertSuccess } from "@shared/Alerts";
import { renderRegexField, renderTextField } from "@shared/FormUtils";
import EventContext from "@event/EventContext";
import EventDetailsBlankField from "./EventDetailsBlankField";
import EventDetailsRegistrationFieldForm from "./EventDetailsRegistrationFieldForm";
import EventDetailsRegistrationSectionOptionsNew from "./EventDetailsRegistrationSectionOptionsNew";

const EventDetailsRegistrationSectionPreview = props => {
  const { addField, fields, removeField, updateField } = props;
  const { apiRoot } = useContext(EventContext).values;
  const [fieldAdd, setFieldAdd] = useState(false);
  const [fieldBeingCloned, setFieldBeingCloned] = useState(null);
  const [fieldEdit, setFieldEdit] = useState({id: null});

  const revealAddFieldForm = () => {
    setFieldBeingCloned(null)
    setFieldAdd(true);
  };

  const revealCloneFieldForm = (field) => {
    setFieldBeingCloned(field);
    setFieldAdd(true);
  };

  const hideAddFieldForm = () => {
    setFieldBeingCloned(null)
    setFieldAdd(false);
  };

  const addSuccess = response => {
    alertSuccess("Field saved successfully");
    addField(response.data.field);
    setFieldAdd(false);
  };

  const addFailure = response => {
    alertError(`Failed adding field: ${response.data.error}`);
  };

  const editField = id => {
    const field = fields.find(f => f.id === id);
    if (field) {
      setFieldEdit(field);
    }
  };

  const resetEdit = () => {
    setFieldEdit({id: null});
  };

  const renderCloneFieldLink = field => (
    <span
      className="sg-mgmt-link sg-mgmt-reg-field-type"
      onClick={() => {
        revealCloneFieldForm(field);
      }}
    >
      Clone
    </span>
  );


  const renderEditFieldLink = field => (
    <span
      className="sg-mgmt-link sg-mgmt-reg-field-type"
      onClick={() => {
        editField(field.id);
      }}
    >
      Edit
    </span>
  );

  const renderDeleteFieldLink = field => (
    <span
      className="sg-mgmt-link sg-mgmt-reg-field-type"
      onClick={() => {
        removeField(field.id);
      }}
    >
      Delete
    </span>
  );

  const renderCancelFieldLink = () => (
    <span
      className="sg-mgmt-link sg-mgmt-reg-field-type"
      onClick={() => {
        resetEdit();
      }}
    >
      Cancel
    </span>
  );

  const renderUpdateFieldLink = submitForm => (
    <span
      className="sg-mgmt-link sg-mgmt-reg-field-type"
      onClick={() => {
        submitForm();
      }}
    >
      Save
    </span>
  );


  const renderField = field => {
    if (field.id === fieldEdit.id) {
      return renderFieldEdit(field);
    }
    return renderFieldInfo(field);
  };

  const renderFieldInfo = field => (
    <tr key={field.gid}>
      <td className="border px-4 py-2 text-sm align-top">{field.label}</td>
      <td className="border px-4 py-2 text-sm align-top">{field.slug}</td>
      <td className="border px-4 py-2 text-sm align-top">
        {field.field_format}
      </td>
      <td className="border px-4 py-2 text-sm align-top">
        {field.options.map(opt => (
          <div key={opt.id}>{`${opt.slug}: ${opt.label}`}</div>
        ))}
      </td>
      <td className="border px-4 py-2 text-sm align-top">
        {field.sort_order}
      </td>
      <td className="border px-4 py-2 text-sm align-top">
        {renderEditFieldLink(field)}
        {renderCloneFieldLink(field)}
        {renderDeleteFieldLink(field)}
      </td>
    </tr>
  );

  const renderFormField = (name) => (
    <Field
      key={name}
      className="sg-mgmt-form-input"
      type="text"
      name={`field[${name}]`}
      autoComplete="off"
    />
  );

  const renderOptionsField = (opt, idx) => {
    return (
      <div key={opt.id}>
        <div>
          {opt.slug}
        </div>
        <div>
          {renderTextField(
            "Label (question)",
            `field[event_field_options][${idx}][label]`
          )}
          <input type="hidden" name={`field[event_field_options][${idx}][slug]`} value={opt.slug} />
          <input type="hidden" name={`field[event_field_options][${idx}][id]`} value={opt.id} />
        </div>
      </div>
    );
  };

  const renderFieldEdit = field => (
    <Formik
      key={`edit-field-${field.id}`}
      initialValues={{
        field: {
          label: field.label,
          slug: field.slug,
          sort_order: field.sort_order,
          event_field_options: field.options
        }
      }}
      onSubmit={(values, { setSubmitting }) => {
        const form = document.getElementById("sg-mgmt-reg-field-edit");
        const formData = new FormData(form);

        const token = document.querySelector("[name=csrf-token]").content;
        axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

        axios({
          url: urljoin(apiRoot, "/details/registration", `/${field.id}`),
          method: "PATCH",
          data: formData
        })
          .then(response => {
            if (response.data.error === null) {
              updateField(response.data.field);
              resetEdit();
              setSubmitting(false);
            } else {
              alertError(response.data.error);
              setSubmitting(false);
            }
          })
          // .catch(error => {
          //   alertHttpError(error);
          // });
      }}
    >
      {({ submitForm }) => (
        <tr key={field.gid}>
          <td className="border px-4 py-2 text-sm align-top">
            {renderFormField("label")}
          </td>
          <td className="border px-4 py-2 text-sm align-top">
            {renderFormField("slug")}
          </td>
          <td className="border px-4 py-2 text-sm align-top">
            {field.field_format}
          </td>
          <td className="border px-4 py-2 text-sm align-top">
            {field.options.map((opt, idx) => (
              renderOptionsField(opt, idx)
            ))}
            <EventDetailsRegistrationSectionOptionsNew field={field} />
          </td>
          <td className="border px-4 py-2 text-sm align-top">
            {renderFormField("sort_order")}
          </td>
          <td className="border px-4 py-2 text-sm align-top">
            {renderCancelFieldLink()}
            {renderUpdateFieldLink(submitForm)}
          </td>
        </tr>
      )}
    </Formik>
  );

  const renderFields = () => {
    return (
      <form className="sg-mgmt-form" id="sg-mgmt-reg-field-edit">
        <table className="table-auto">
          <thead>
            <tr>
              {/* <th className="border px-4 py-2">Order</th> */}
              <th className="border px-4 py-2">Label</th>
              <th className="border px-4 py-2">Slug</th>
              <th className="border px-4 py-2">Type</th>
              <th className="border px-4 py-2">Options</th>
              <th className="border px-4 py-2">Sort Order</th>
              <th className="border px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {fields.map(field => renderField(field))}
          </tbody>
        </table>
      </form>
    );
  };

  const renderNoFields = () => {
    if (fields.length === 0) {
      return <p>No custom registration fields created.</p>;
    }
    return <></>;
  };

  const renderAddField = () => {
    if (!fieldAdd) {
      return (
        <div
          className="sg-mgmt-reg-field-add sg-mgmt-link"
          onClick={revealAddFieldForm}
        >
          + Add Field
        </div>
      );
    }
    return <></>;
  };
  
  const getClonedBlankField = (field) => {
    //Deep copy. Yes I hate this too.
    const new_field = JSON.parse(JSON.stringify(field));
    new_field['id'] = null;
    new_field['event_field_options'] = field.options
    return new_field
  }
  
  const renderAddFieldForm = () => {
    if (fieldAdd) {
      return (
        <div className="sg-mgmt-reg-field-form-container">
          <EventDetailsRegistrationFieldForm
            key={(fieldBeingCloned||{}).id||"new"}
            field={fieldBeingCloned ? getClonedBlankField(fieldBeingCloned) : EventDetailsBlankField}
            callbackFailure={addFailure}
            callbackSuccess={addSuccess}
            cancelButton={hideAddFieldForm}
          />
        </div>
      );
    }
    return <></>;
  };

  return (
    <div className="sg-mgmt-details-section">
      <h2>Custom Fields</h2>
      {renderFields()}
      {renderNoFields()}
      {renderAddFieldForm()}
      {renderAddField()}
    </div>
  );
};

export default EventDetailsRegistrationSectionPreview;
