import React from "react";
import Modal from "react-modal";
import { alertError, alertSuccess } from "@shared/Alerts";
import { Field, Formik, Form } from "formik";
import {
  renderSubmitButton,
  renderTextAreaField,
  renderCancelButton
} from "@shared/FormUtils";
import Stack from "@mui/material/Stack";
const FormsFormPageQuestionImportOptions = props => {
  const { modalVisible, resetModal, update } = props;
  const renderForm = () => {
    return (
      <Formik
        initialValues={{
          import_field: ""
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          const filteredValues = (values.import_field||"").split("\n").map(x=>x.trim()).filter(x=>x)
          update(filteredValues);
          setSubmitting(false);
        }}
      >
        {({ values, isSubmitting }) => (
          <Form className="sg-mgmt-form" id={"import-field"}>
            <div className="sg-mgmt-form-container">
              
              <div className="sg-mgmt-form-row">
                {renderTextAreaField("Options to import (one option per line)", "import_field")}
              </div>
            </div>
            <Stack direction="row" spacing={2}>
              {renderSubmitButton("Import", isSubmitting)}
              {renderCancelButton("Cancel", resetModal)}
            </Stack>
          </Form>
        )}
      </Formik>
    );
  };

  return (
    <Modal
      className="sg-mgmt-modal"
      contentLabel={"Import Options"}
      isOpen={modalVisible}
      overlayClassName="sg-mgmt-modal-overlay"
      onRequestClose={resetModal}
    >
      <div className="sg-mgmt-modal-frame">
        <div className="sg-mgmt-modal-title">Import Options</div>
        <div className="sg-mgmt-modal-content">
          {renderForm()}
        </div>
      </div>
    </Modal>
  );
};

export default FormsFormPageQuestionImportOptions;
