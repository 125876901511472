import React, { useRef, useState } from "react";

const Iframe = props => {
  const { content } = props;
  const ref = useRef();
  const [height, setHeight] = useState("0px");
   const onLoad = () => {
    setHeight(ref.current.contentWindow.document.body.scrollHeight + "px");
  };

  return (
    <iframe
      src="about:blank"
      onLoad={onLoad}
      scrolling={props.scrolling||"no"}
      height={height}
      width="100%"
      frameBorder="0"
      ref={ref}
      srcDoc={content}
    />
  );
};


export default Iframe;
