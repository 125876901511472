import React from "react";

import WidgetDeleteIcon from "./WidgetDeleteIcon";

const WidgetErrorFallback = ({ editMode, error, id, onUpdate }) => (
  <div className="h-widget w-full border border-bg-2 shadow-widget relative">
    <div className="p-4">
      <h2>This widget was unable to be loaded.</h2>
      <p>Please try again.</p>
      <p>{error?.message}</p>
    </div>
    {editMode && <WidgetDeleteIcon id={id} onUpdate={onUpdate} />}
  </div>
);

export default WidgetErrorFallback;
