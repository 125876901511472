import React from "react";

import { FormControl, FormLabel, Select, MenuItem } from "@mui/material";
import { Controller } from "react-hook-form";

const WidgetSizeOptions = ({
  control,
  disabled = false
}) => {

  if (disabled) {
    return (
      <div className="my-4 text-sm">
        {"Size cannot be changed for this widget."}
      </div>
    );
  }

  return (
    <div className="mb-4 mt-4">
      <div className="mb-4 w-64">
        <FormControl fullWidth>
          <FormLabel>Widget Size</FormLabel>
          <Controller
            name="widget_size"
            control={control}
            rules={{ required: "Size is required" }}
            render={({ field: { value, onChange, ...field } }) => (
              <Select {...field} fullWidth onChange={onChange} value={value} variant="outlined" size="small">
                <MenuItem value={1}>Half Width</MenuItem>
                <MenuItem value={2}>Full Width</MenuItem>
              </Select>
            )}
          />
        </FormControl>
      </div>
    </div>
  );
};

export default WidgetSizeOptions;
