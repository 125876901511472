import React, { useEffect, useContext, useState } from "react";

import Stack from "@mui/material/Stack";
import axios from "axios";
import { Formik, Form } from "formik";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import { alertHttpError } from "@shared/Alerts";
import { renderSubmitButton, renderCancelButton, renderSelectFieldFilterable } from "@shared/FormUtils";
import Loading from "@shared/Loading";

const MetadataCopyEventForm = ({
  fieldType,
  cancelButton = () => {},
  callbackSuccess = () => {},
  callbackFailure = () => {}
}) => {
  const { apiRoot } = useContext(EventContext).values;
  const [events, setEvents] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);

  const fetchEvents = async () => {
    try {
      const result = await axios("/-/events");
      setEvents(result.data);
    } catch (error) {
      alertHttpError(error);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  const formConfig = (() => {
    return {
      alert: "added",
      formId: "sg-mgmt-form-token-add",
      formUrl: urljoin(apiRoot, "/metadata/copy_event"),
      method: "POST",
      saveButton: "Save",
      title: "Copy Event"
    };
  })();

  const renderForm = () => {
    return (
      <Formik
        initialValues={{
          duplicating_event_gid: null,
          field_type: fieldType
        }}
        onSubmit={async (values) => {
          setSubmitting(true);
          const csrfToken = document.querySelector("[name=csrf-token]").content;
          axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
          axios({
            url: formConfig.formUrl,
            method: formConfig.method,
            data: values
          }).then((response) => {
            setSubmitting(false);
            if (response.data.error === null) {
              callbackSuccess(response);
            } else {
              callbackFailure(response);
            }
          });
        }}
      >
        <Form className="sg-mgmt-form" id={formConfig.formId}>
          <div className="sg-mgmt-form-container">
            <h2>Select an event to copy</h2>
            <div className="sg-mgmt-form-row">
              This will add all custom fields from the selected event to the current event.
              <br />
              <br />
            </div>

            <div className="sg-mgmt-form-row">
              <br />
              <br />
              {renderSelectFieldFilterable(
                "Event",
                "duplicating_event_gid",
                [{ value: null, label: "" }].concat(
                  events.map((x) => {
                    return {
                      value: x.gid,
                      label: x.name
                    };
                  })
                ),
                false,
                true
              )}
            </div>

            <Stack direction="row" spacing={2}>
              {renderSubmitButton(formConfig.saveButton, isSubmitting)}
              {renderCancelButton("Cancel", cancelButton)}
            </Stack>
          </div>
        </Form>
      </Formik>
    );
  };

  if (events) {
    return renderForm();
  } else {
    return <Loading />;
  }
};

export default MetadataCopyEventForm;
