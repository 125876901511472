import React, { useContext, useEffect, useState } from "react";
import { toDate, format, utcToZonedTime } from "date-fns-tz";
import Modal from "react-modal";
import axios from "axios";
import urljoin from "url-join";
import { alertError, alertHttpError, alertSuccess } from "@shared/Alerts";
import Loading from "@shared/Loading";
import EventContext from "@event/EventContext";
import { renderCancelButton } from "@shared/FormUtils";
import { renderButton, renderDeleteButton } from "@shared/FormUtils";
import { Stack } from "@mui/material";

const EventMeetings1On1RequestsModalReview = ({
  requestId  = 0,
  modalVisible,
  removeRequest,
  resetModal
}) => {
  const { apiRoot, event } = useContext(EventContext).values;
  const [request, setRequest] = useState(null);
  const [fetched, setFetched] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const [rejectReason, setRejectReason] = useState("");

  Modal.setAppElement("#root");

  useEffect(() => {
    const fetchRequest = async () => {
      try {
        const result = await axios(
          urljoin(apiRoot, "/meeting1on1s/requests", `/${requestId}`)
        );
        setRequest(result.data.request);
        setFetched(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    if (requestId > 0) {
      fetchRequest();
    }
  }, [requestId]);

  const approve = choice => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    const formData = {
      choice
    };
    axios({
      url: urljoin(
        apiRoot,
        "/meeting1on1s/requests/",
        `${requestId}`,
        "/approve"
      ),
      method: "POST",
      data: formData
    })
      .then(response => {
        if (response.data.error === null) {
          alertSuccess("Request approved, meeting created");
          removeRequest(response.data.request_id);
          resetModal();
        } else {
          alertError(response.data.error);
        }
      })
      .catch(error => {
        alertHttpError(error);
      });
  };

  const approveCombo = () => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    const formData = {
      combo: true
    };
    axios({
      url: urljoin(
        apiRoot,
        "/meeting1on1s/requests/",
        `${requestId}`,
        "/approve"
      ),
      method: "POST",
      data: formData
    })
      .then(response => {
        if (response.data.error === null) {
          alertSuccess("Request approved, meeting created");
          removeRequest(response.data.request_id);
          resetModal();
        } else {
          alertError(response.data.error);
        }
      })
      .catch(error => {
        alertHttpError(error);
      });
  };

  const submitReject = () => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      url: urljoin(
        apiRoot,
        "/meeting1on1s/requests/",
        `${requestId}`,
        "/reject"
      ),
      method: "POST",
      data: {
        request: {
          reject_reason: rejectReason
        }
      }
    })
      .then(response => {
        if (response.data.error === null) {
          alertSuccess("Request declined, submitter has been notified.");
          removeRequest(response.data.request_id);
          resetModal();
        } else {
          alertError(response.data.error);
        }
      })
      .catch(error => {
        alertHttpError(error);
      });
  };

  const choiceText = choice =>
    parseInt(choice, 10) === 1 ? "First" : "Second";

  const renderCloseButton = () => (
    renderCancelButton(
      "Cancel",
      resetModal
    )
  );

  const renderApproveButton = choice => {
    if (request.combo) {
      return <></>;
    }
    return (
      renderButton(
        `Approve ${choiceText(choice)} Choice`,
        ()=>approve(choice)
      )
    );
  };

  const renderComboApproveButton = () => {
    if (request.combo) {
      return (
        renderButton(
          "Approve",
          approveCombo
        )
      );
    }
    return <></>;
  };

  const renderRejectButton = () => {
    return (
      renderDeleteButton(
        "Decline",
        submitReject
      )
    );
  };

  const renderReject = () => {
    if (showReject) {
      return (
        <div>
          <div className="sg-mgmt-form-input-container">
            <label>Enter reason for declining meeting request:</label>
            <textarea
              name="reject_reason"
              className="sg-mgmt-form-input"
              onChange={e => {
                setRejectReason(e.target.value);
              }}
            />
          </div>
          {
            renderDeleteButton(
              "Submit Rejection",
              submitReject
            )
          }
        </div>
      );
    }
    return <></>;
  };

  const renderApproveRejectButtons = () => {
    if (fetched) {
      return (
        <Stack direction="row" spacing={2}>
          {renderApproveButton(1)}
          {renderApproveButton(2)}
          {renderComboApproveButton()}
          {renderRejectButton()}
        </Stack>
      );
    }
    return <></>;
  };

  const renderChoice = num => {
    if (request.combo) {
      return <></>;
    }
    const choice = request.hosts.find(h => parseInt(h.choice, 10) === num);
    if (choice) {
      const parsedDatetime = utcToZonedTime(
        choice.date_and_time,
        event.time_zone
      );
      const host = choice.meeting_host;
      return (
        <div className="sg-mgmt-1on1-view-section">
          <h2>{choiceText(choice.choice)} Choice:</h2>
          <div>{`${host.name_first} ${host.name_last} (${host.email})`}</div>
          <div>
            {format(parsedDatetime, "EEEE, LLLL do", {
              timeZone: event.time_zone
            })}
            ,{" "}
            {format(parsedDatetime, "h:mm aaaa", { timeZone: event.time_zone })}{" "}
            PST
          </div>
        </div>
      );
    }
    return <></>;
  };

  const renderCombo = () => {
    if (request.combo) {
      const firstHost = request.hosts[0];
      const secondHost = request.hosts[1];
      const parsedDatetime = utcToZonedTime(
        firstHost.date_and_time,
        event.time_zone
      );
      return (
        <div className="sg-mgmt-1on1-view-section">
          <h2>Executives:</h2>
          <div>{`${firstHost.meeting_host.name_first} ${firstHost.meeting_host.name_last} (${firstHost.meeting_host.email})`}</div>
          <div>{`${secondHost.meeting_host.name_first} ${secondHost.meeting_host.name_last} (${secondHost.meeting_host.email})`}</div>
          <div>
            {format(parsedDatetime, "EEEE, LLLL do", {
              timeZone: event.time_zone
            })}
            ,{" "}
            {format(parsedDatetime, "h:mm aaaa", { timeZone: event.time_zone })}{" "}
            PST
          </div>
        </div>
      );
    }
    return <></>;
  };

  const renderAttendee = () => {
    const { attendee } = request;
    return (
      <div className="sg-mgmt-1on1-view-section">
        <h2>Attendee:</h2>
        <div>{`${attendee.name_first} ${attendee.name_last} (${attendee.email})`}</div>
        <div>{attendee.job_title}</div>
        <div>{attendee.company}</div>
      </div>
    );
  };

  const renderRequest = () => {
    if (fetched) {
      return (
        <div>
          {renderChoice(1)}
          {renderChoice(2)}
          {renderCombo()}
          {renderAttendee()}
          <div className="sg-mgmt-1on1-view-section">
            <h2>Meeting Details</h2>
            <div>
              <b>Objective #1:</b> {request.data.objective_1}
            </div>
            <div>
              <b>Objective #2:</b> {request.data.objective_2}
            </div>
            <div>
              <b>Objective #3:</b> {request.data.objective_3}
            </div>
            <div>
              <b>Company Overview:</b> {request.data.company_overview}
            </div>
          </div>
        </div>
      );
    }
    return <Loading />;
  };

  return (
    <Modal
      className="sg-mgmt-modal"
      contentLabel="Review Request"
      isOpen={modalVisible}
      overlayClassName="sg-mgmt-modal-overlay"
      onRequestClose={resetModal}
    >
      <div className="sg-mgmt-modal-frame">
        <div className="sg-mgmt-modal-title">Review Request</div>
        <div className="sg-mgmt-modal-content">
          {renderRequest()}
          <Stack direction="row" spacing={2}>
            {renderCloseButton()}
            {renderApproveRejectButtons()}
          </Stack>
          <div className="sg-mgmt-1on1-view-section">{renderReject()}</div>
        </div>
      </div>
    </Modal>
  );
};

export default EventMeetings1On1RequestsModalReview;
