import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import Modal from "react-modal";
import urljoin from "url-join";
import { alertHttpError } from "@shared/Alerts";
import Loading from "@shared/Loading";
import EventContext from "@event/EventContext";
import EventMeetings1On1Context from "./EventMeetings1On1Context";
import EventMeetings1On1FormEdit from "./form/EventMeetings1On1FormEdit";

const EventMeetings1On1ModalEdit = (props) => {
  const { apiRoot } = useContext(EventContext).values;
  const { meetingId = 0, modalVisible, resetModal } = props;
  const [meeting, setMeeting] = useState(null);
  const [fetched, setFetched] = useState(false);

  Modal.setAppElement("#root");

  useEffect(() => {
    const fetchMeeting = async () => {
      try {
        const result = await axios(
          urljoin(apiRoot, "/meetings", `/${meetingId}`)
        );
        setMeeting(result.data.meeting);
        setFetched(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    if (meetingId > 0) {
      fetchMeeting();
    }
  }, [meetingId]);

  const reset = () => {
    resetModal();
    setFetched(false);
  };

  const context = () => ({
    meeting
  });

  const renderForm = () => {
    if (fetched) {
      return (
        <EventMeetings1On1FormEdit
          {...props}
          cancel={reset}
          callbackSuccess={reset}
          meeting={meeting}
        />
      );
    }
    return <Loading />;
  };

  return (
    <Modal
      className="sg-mgmt-modal"
      contentLabel="Add Meeting"
      isOpen={modalVisible}
      overlayClassName="sg-mgmt-modal-overlay"
      onRequestClose={resetModal}
    >
      <EventMeetings1On1Context.Provider value={context()}>
        <div className="sg-mgmt-modal-frame">
          <div className="sg-mgmt-modal-title">Edit Meeting</div>
          <div className="sg-mgmt-modal-content">{renderForm()}</div>
        </div>
      </EventMeetings1On1Context.Provider>
    </Modal>
  );
};

export default EventMeetings1On1ModalEdit;
