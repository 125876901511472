import React, { useContext, useState } from "react";

import { useConfirm } from "material-ui-confirm";

import EventContext from "@event/EventContext";
import EventUserContext from "@event/EventUserContext";
import PeopleEventParticipantsModal from "@people/participants/PeopleEventParticipantsModal";
import GrowlTable from "@shared/GrowlTable";

import TicketingPackageErrorModal from "./TicketingPackageErrorModal";

const TicketingPackageAssignmentParticipantTable = (props) => {
  const {
    assignPackage,
    columns,
    metadataFields,
    packageTickets,
    packageType,
    participants,
    refreshParticipants,
    regFields,
    tags,
    types,
    unassignTicket
  } = props;

  const { apiRoot, event, rootUrl } = useContext(EventContext).values;
  const { user } = useContext(EventUserContext);
  const confirm = useConfirm();

  const [editParticipantId, setEditParticipantId] = useState(null);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);

  const editPersonClick = (id) => {
    setEditParticipantId(id);
    setEditModalVisible(true);
  };

  const addIndividual = () => {
    refreshParticipants.mutate();
  };

  const updateParticipant = () => {
    refreshParticipants.mutate();
  };

  const editEnabled = () => {
    if (user.role === "basic" && !user.permission.participants_edit) {
      return false;
    }
    return true;
  };

  const renderEditAction = (rowData) => {
    if (!editEnabled()) {
      return <></>;
    }

    return (
      <>
        <span
          className="cursor-pointer"
          onClick={() => {
            editPersonClick(rowData.id);
          }}
        >
          Edit
        </span>
      </>
    );
  };

  const renderAssignPackage = (rowData) => {
    return (
      <>
        <span
          className="cursor-pointer"
          onClick={() => {
            assignPackageClick(rowData.id);
          }}
        >
          Assign Package
        </span>
      </>
    );
  };

  const assignPackageClick = (participant_id) => {
    let packageAvailable = checkPackageAvailable();
    let assignmentMessage = checkAlreadyAssigned(participant_id);
    if (!packageAvailable) {
      confirm({
        title: "Unavailable Tickets",
        description: "Package/Tickets have reached their capacity."
      });
    } else if (assignmentMessage.assigned) {
      setErrorModalVisible(assignmentMessage);
    } else {
      assignPackage(participant_id);
    }
  };

  const checkPackageAvailable = () => {
    let available = true;
    packageTickets.forEach((ticket) => {
      if (ticket.total_count_type === "limited") {
        if (ticket.total_assigned >= ticket.total_count) {
          available = false;
        }
      }
    });
    return available;
  };

  const resetAssignmentMessage = () => {
    setErrorModalVisible({ assigned: false, type: "", participant_ticket_id: "" });
  };

  const checkAlreadyAssigned = (participant_id) => {
    let assignmentMessage = { assigned: false, type: "", participant_ticket_id: "" };
    packageTickets.forEach((packageTicket) => {
      packageTicket.ticket_type.participant_tickets.forEach((participantTicket) => {
        if (participantTicket.event_participant_id === participant_id) {
          if (participantTicket.ticketing_package_type_id === null) {
            assignmentMessage = {
              assigned: true,
              type: "ticket-assigned",
              participant_ticket_id: participantTicket.id,
              participant_id: participant_id,
              ticket_name: packageTicket.ticket_type.name
            };
          } else if (participantTicket.ticketing_package_type_id === packageType.id) {
            assignmentMessage = {
              assigned: true,
              type: "package-assigned",
              participant_ticket_id: participantTicket.id,
              participant_id: participant_id,
              ticket_name: packageTicket.ticket_type.name
            };
          }
        }
      });
    });
    return assignmentMessage;
  };

  const tableColumns = [
    ...columns,
    {
      headerName: "Actions",
      field: "actions",
      type: "actions",
      flex: 1,
      minWidth: 200,
      getActions: (params) => [renderEditAction(params.row), renderAssignPackage(params.row)]
    }
  ];

  const closeEditModal = () => {
    setEditModalVisible(false);
  };

  const resetEditModal = () => {
    closeEditModal();
    setEditParticipantId(null);
  };

  const renderEditModal = () => {
    if (!editParticipantId) {
      return <></>;
    }

    return (
      <PeopleEventParticipantsModal
        apiRoot={apiRoot}
        addIndividual={addIndividual}
        individuals={[]}
        participantId={editParticipantId}
        closeModal={closeEditModal}
        modalVisible={editModalVisible}
        metadataFields={metadataFields}
        regFields={regFields}
        resetModal={resetEditModal}
        rootUrl={rootUrl}
        tags={tags}
        types={types}
        updateFunc={updateParticipant}
      />
    );
  };

  const renderErrorModal = () => {
    return (
      <TicketingPackageErrorModal
        modalVisible={errorModalVisible.assigned}
        assignmentMessage={errorModalVisible}
        resetModal={resetAssignmentMessage}
        assignPackage={assignPackage}
        unassignTicket={unassignTicket}
      />
    );
  };

  return (
    <div>
      <GrowlTable
        columns={tableColumns}
        items={participants}
        tableName={`${event.slug}-package-assignment-participants`}
      />
      {renderEditModal()}
      {renderErrorModal()}
    </div>
  );
};

export default TicketingPackageAssignmentParticipantTable;
