import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import Modal from "react-modal";
import urljoin from "url-join";
import { alertHttpError } from "@shared/Alerts";
import Loading from "@shared/Loading";
import Iframe from "@shared/Iframe";
import EventContext from "@event/EventContext";
import { renderCancelButton } from "@shared/FormUtils";

const CommunicationsEmailTemplatePreviewModal = props => {
  const { apiRoot } = useContext(EventContext).values;
  const { templateId, modalClose, modalVisible } = props;
  const [template, setTemplate] = useState(null);
  const [fetched, setFetched] = useState(false);

  Modal.setAppElement("#root");

  useEffect(() => {
    const fetchTemplate = async () => {
      try {
        const result = await axios(
          urljoin(apiRoot, `/communications/email_templates/${templateId}`)
        );
        setTemplate(result.data.template);
        setFetched(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchTemplate();
  }, [apiRoot, templateId]);

  const renderPreview = () => {
    return (
      <div>
        <h2>Preview</h2>
        <Iframe scrolling="no" content={template.html} />
      </div>
    );
  };

  const renderGid = () => {
    return (
      <div className="sg-mgmt-modal-view-field">
        <span className="sg-mgmt-modal-view-field-label">GID</span>
        :&nbsp;
        {template.gid}
      </div>
    );
  };

  const renderModalBody = () => {
    if (fetched) {
      return (
        <div>
          {renderGid()}
          {renderPreview()}
          <br />
          {renderCancelButton("Close", modalClose)}
        </div>
      );
    }
    return <Loading />;
  };

  const renderTitle = () => {
    if (fetched) { return template.name; }
    return "";
  };

  return (
    <Modal
      className="sg-mgmt-modal"
      contentLabel="Email Template Preview"
      isOpen={modalVisible}
      overlayClassName="sg-mgmt-modal-overlay"
      onRequestClose={modalClose}
    >
      <div className="sg-mgmt-modal-frame">
        <div className="sg-mgmt-modal-title">{renderTitle()}</div>
        <div className="sg-mgmt-modal-content">
          {renderModalBody()}
        </div>
      </div>
    </Modal>
  );
};

export default CommunicationsEmailTemplatePreviewModal;
