import React, { useContext, useState } from "react";

import { UploadFile as UploadFileIcon } from "@mui/icons-material";
import Stack from "@mui/material/Stack";
import axios from "axios";
import clsx from "clsx";
import { Formik, Form } from "formik";
import Dropzone from "react-dropzone";
import { useNavigate } from "react-router";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import { alertError, alertHttpError } from "@shared/Alerts";
import { renderSubmitButton, renderCancelButton } from "@shared/FormUtils";

const PeopleEventParticipantsImportForm = ({
  callbackSuccess = () => {
    alertError("not implemented");
  }
}) => {
  const navigate = useNavigate();

  const { apiRoot } = useContext(EventContext).values;
  const [sheet, setSheet] = useState({ id: null });
  const [sheetDropped, setSheetDropped] = useState(false);
  const dropzoneAccept = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

  const formConfig = {
    alert: "added",
    buttonColor: "secondary",
    cancelUrl: urljoin(apiRoot, "/participants"),
    formId: "sg-mgmt-form-list-import",
    formUrl: urljoin(apiRoot, "/participants/import"),
    method: "POST",
    submitText: "Continue",
    title: "Import Participants"
  };

  const formInitialValues = () => {
    return {
      sheet: "",
      csv_data: ""
    };
  };

  const onDrop = (acceptedFiles) => {
    const reader = new FileReader();
    if (typeof window.FileReader !== "undefined") {
      reader.onload = (_e) => {
        setSheet(acceptedFiles[0]);
        setSheetDropped(true);
      };
      reader.readAsDataURL(acceptedFiles[0]);
    } else {
      setSheet(acceptedFiles[0]);
      setSheetDropped(true);
    }
  };

  const renderSheet = () => {
    if (sheetDropped) {
      return (
        <div className="sg-mgmt-form-input-import-tile-prompt">
          Sheet Attached
          <i className="far fa-file-excel sg-mgmt-form-input-import-icon mx-auto my-2" />
          {sheet.name}
        </div>
      );
    }
    return (
      <div className="sg-mgmt-form-input-import-tile-prompt items-center">
        <UploadFileIcon size="small" color="primary" />
        <i className="far fa-file-excel sg-mgmt-form-input-import-icon mx-auto my-2" />
        <div>
          <span className="text-blue underline">Click to Upload</span> or Drag and Drop
        </div>
      </div>
    );
  };

  const renderSheetField = () => {
    return (
      <div>
        <Dropzone accept={dropzoneAccept} onDrop={onDrop} name="import[sheet]">
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <>
                <div
                  {...getRootProps()}
                  className={clsx("cursor-pointer", "dropzone", "sg-mgmt-form-input-import-tile", {
                    "dropzone--isActive": isDragActive
                  })}
                >
                  <input {...getInputProps()} />
                  {renderSheet()}
                </div>
              </>
            );
          }}
        </Dropzone>
      </div>
    );
  };

  const renderParticipantListImportForm = () => {
    return (
      <Formik
        initialValues={{
          import: formInitialValues()
        }}
        onSubmit={({ setSubmitting }) => {
          const form = document.getElementById(formConfig.formId);
          const formData = new FormData(form);
          const token = document.querySelector("[name=csrf-token]").content;
          axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

          if (sheet) {
            formData.set("import[sheet]", sheet);
          }

          axios({
            url: formConfig.formUrl,
            method: formConfig.method,
            data: formData
          })
            .then((response) => {
              if (response.data.error == null) {
                callbackSuccess(response.data);
              } else {
                // callbackFailure(response);
                setSubmitting(false);
                alertError(response.data.error);
              }
            })
            .catch((error) => {
              alertHttpError(error);
            });
        }}
      >
        {({ isSubmitting }) => (
          <Form id={formConfig.formId}>
            <div className="my-6 text-lg font-normal text-sg-orange">{"Step 1: Upload Excel Spreadsheet"}</div>
            <div className="my-2">{renderSheetField()}</div>
            <Stack direction="row" spacing={2}>
              {renderSubmitButton(formConfig.submitText, isSubmitting || !sheetDropped, {
                color: formConfig.buttonColor
              })}
              {renderCancelButton(
                "Cancel",
                () => {
                  navigate(formConfig.cancelUrl);
                },
                { color: formConfig.buttonColor }
              )}
            </Stack>
          </Form>
        )}
      </Formik>
    );
  };

  return <div>{renderParticipantListImportForm()}</div>;
};

export default PeopleEventParticipantsImportForm;
