import React, { useContext, useEffect, useState } from "react";

import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "@mui/material";
import axios from "axios";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import EventUserContext from "@event/EventUserContext";
import { alertError, alertHttpError, alertSuccess } from "@shared/Alerts";
import { renderCreateButton } from "@shared/FormUtils";
import GrowlTable from "@shared/GrowlTable";
import Loading from "@shared/Loading";
import PageHeader from "@shared/PageHeader";
import { formatTime, formatTimeUtcToZone } from "@shared/TimeUtils";
import UiToggle from "@shared/UiToggle";
import { isDeveloper } from "@shared/UserUtils";

const CommunicationsEmails = (props) => {
  const { apiRoot, event } = useContext(EventContext).values;
  const {
    goEdit,
    goNew,
    goView,
    goViewTransactional,
    setClickedView,
    setClickedTracking,
    setEditEmail,
    setViewEmail,
    setViewTransactional
  } = props;
  const [emails, setEmails] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [fetchedEmails, setFetchedEmails] = useState(false);
  const [fetchedTemplates, setFetchedTemplates] = useState(false);
  const [showArchived, setShowArchived] = useState(false);
  const [showTransactional, setShowTransactional] = useState(false);
  const { user } = useContext(EventUserContext);

  useEffect(() => {
    // reset click tracks
    setClickedView(false);
    setClickedTracking(false);

    const fetchEmails = async () => {
      try {
        const result = await axios(urljoin(apiRoot, "/communications/emails"));
        setEmails(result.data.emails);
        setFetchedEmails(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    const fetchTemplates = async () => {
      try {
        const result = await axios(urljoin(apiRoot, "/communications/email_templates"));
        setTemplates(result.data.templates);
        setFetchedTemplates(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchTemplates();
    fetchEmails();
  }, [apiRoot, setClickedTracking, setClickedView]);

  const fetched = () => {
    return fetchedEmails && fetchedTemplates;
  };

  const updateEmails = (email) => {
    const newEmails = emails.map((em) => (em.gid === email.gid ? email : em));
    setEmails(newEmails);
  };

  const archive = (id) => {
    const postData = { email_id: id };
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      url: urljoin(apiRoot, "/communications/emails/", `/${id}`, "/archive"),
      method: "PATCH",
      data: postData
    }).then((response) => {
      if (response.data.error === null) {
        updateEmails(response.data.email);
        alertSuccess("Email archived");
      } else {
        alertError("Failed archiving email");
      }
    });
  };

  const unarchive = (id) => {
    const postData = { email_id: id };
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      url: urljoin(apiRoot, "/communications/emails/", `/${id}`, "/unarchive"),
      method: "PATCH",
      data: postData
    }).then((response) => {
      if (response.data.error === null) {
        updateEmails(response.data.email);
        alertSuccess("Email unarchived");
      } else {
        alertError("Failed unarchiving email");
      }
    });
  };

  const clearSchedule = (id) => {
    const postData = {
      email: {
        status: "saved",
        scheduled_time: null
      }
    };
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      url: urljoin(apiRoot, "/communications/emails/", `/${id}`),
      method: "PATCH",
      data: postData
    }).then((response) => {
      if (response.data.error === null) {
        updateEmails(response.data.email);
        alertSuccess("Email schedule cleared");
      } else {
        alertError("Failed clearing email schedule");
      }
    });
  };

  const editEnabled = () => {
    if (user.role === "basic" && !user.permission.communications_send) {
      return false;
    }
    return true;
  };

  const renderEmailName = (name) => {
    return name || "(no name given)";
  };

  const renderRecipients = (email) => {
    if (email.recipients.length > 0) {
      return "Custom";
    }
    return email.list_name;
  };

  const renderTime = (email) => {
    if (email.status === "processed") {
      return formatTimeUtcToZone(email.completed_timestamp, event.time_zone);
    }

    if (email.status === "scheduled") {
      if (email.immediate) {
        return (
          <>
            Immediate
            <br />
            <Link className="ml-2 no-underline" component="button" onClick={() => clearSchedule(email.id)}>
              Cancel Email
            </Link>
          </>
        );
      }
      return (
        <>
          {formatTimeUtcToZone(email.scheduled_time, event.time_zone)}
          <br />
          <Link className="ml-2 no-underline" component="button" onClick={() => clearSchedule(email.id)}>
            Clear Schedule
          </Link>
        </>
      );
    }

    return formatTime(email.updated_at, event.time_zone);
  };

  const renderStatus = (status) => {
    let values = { color: "sg-saved", label: "Saved" };
    switch (status) {
      case "Scheduled":
        values = { color: "sg-yellow", label: "Scheduled" };
        break;
      case "Completed":
        values = { color: "sg-completed", label: "Completed" };
        break;
      case "Error":
        values = { color: "sg-accent", label: "Error" };
        break;
    }
    return (
      <>
        <span className={`text-${values.color}`}>
          <FontAwesomeIcon icon={faCircle} />
        </span>
        <span className="ml-1">{values.label}</span>
      </>
    );
  };

  const archiveToggle = (id, arch) => {
    if (arch) {
      unarchive(id);
    } else {
      archive(id);
    }
  };

  const archiveLabel = (arch) => {
    if (arch) {
      return "Unarchive";
    }
    return "Archive";
  };

  const renderArchiveLink = (email) => {
    return (
      <>
        <span
          className="cursor-pointer"
          onClick={() => {
            archiveToggle(email.id, email.archived);
          }}
        >
          {archiveLabel(email.archived)}
        </span>
      </>
    );
  };

  const renderViewLink = (email) => (
    <>
      <span
        className="cursor-pointer"
        onClick={() => {
          setClickedView(true);
          setViewEmail(email);
          goView();
        }}
      >
        View
      </span>
    </>
  );

  const renderEditTrackLink = (email) => {
    if (email.status === "processed") {
      return (
        <>
          <span
            className="cursor-pointer"
            onClick={() => {
              setClickedTracking(true);
              setViewEmail(email);
              goView();
            }}
          >
            Track
          </span>
        </>
      );
    }
    return (
      <>
        <span
          className="cursor-pointer"
          onClick={() => {
            setEditEmail(email);
            goEdit();
          }}
        >
          Edit
        </span>
      </>
    );
  };

  const columns = [
    {
      headerName: "Email Name",
      field: "name",
      flex: 1,
      renderCell: (params) => renderEmailName(params.value)
    },
    {
      headerName: "Email Template",
      field: "template_name",
      flex: 1
    },
    {
      headerName: "Recipients",
      field: "recipients",
      flex: 1,
      valueGetter: (_value, row) => row,
      renderCell: (params) => renderRecipients(params.value)
    },
    {
      headerName: "Status",
      field: "status",
      type: "singleSelect",
      valueOptions: ["Completed", "Saved", "Error", "Scheduled"],
      valueGetter: (value) => {
        const str = value === "processed" ? "completed" : value;
        return str.charAt(0).toUpperCase() + str.slice(1);
      },
      renderCell: (params) => renderStatus(params.value),
      flex: 1
    },
    {
      headerName: "Time",
      field: "completed_timestamp",
      flex: 1,
      valueGetter: (_value, row) => row,
      renderCell: (params) => renderTime(params.value),
      sortComparator: (a, b) => {
        const aTime = a.completed_timestamp || "";
        const bTime = b.completed_timestamp || "";
        return aTime.localeCompare(bTime);
      }
    },
    {
      headerName: "Actions",
      field: "actions",
      type: "actions",
      flex: 1,
      minWidth: 200,
      align: "right",
      getActions: (params) => [
        renderEditTrackLink(params.row),
        renderViewLink(params.row),
        renderArchiveLink(params.row)
      ]
    }
  ];

  const renderTransactionalView = (template) => (
    <>
      <span
        onClick={() => {
          setClickedView(true);
          setViewTransactional(template);
          goViewTransactional();
        }}
      >
        View
      </span>
    </>
  );

  const renderTransactionalTrack = (template) => (
    <>
      <span
        onClick={() => {
          setClickedTracking(true);
          setViewTransactional(template);
          goViewTransactional();
        }}
      >
        Track
      </span>
    </>
  );

  const columnsTransactional = [
    {
      headerName: "Transactional Email",
      field: "name",
      flex: 1
    },
    {
      headerName: "Template ID",
      field: "template_id",
      flex: 1
    },
    {
      headerName: "Actions",
      field: "actions",
      type: "actions",
      align: "right",
      flex: 1,
      getActions: (params) => [renderTransactionalView(params.row), renderTransactionalTrack(params.row)]
    }
  ];

  const renderTransactionalTable = () => {
    if (!showTransactional) {
      return <></>;
    }

    const transactionalTemplates = templates.filter((t) => t.message_type === "transactional");
    return (
      <div>
        <GrowlTable
          columns={columnsTransactional}
          items={transactionalTemplates}
          sortField="name"
          sortDirection="asc"
          tableName={`${event.slug}-transactional`}
        />
      </div>
    );
  };

  const renderTransactional = () => {
    if (!isDeveloper(user)) {
      return <></>;
    }

    return (
      <div className="py-2">
        <UiToggle
          flag={showTransactional}
          label="Transactional Emails"
          toggleFunc={() => {
            setShowTransactional(!showTransactional);
          }}
        />
        {renderTransactionalTable()}
      </div>
    );
  };

  const renderArchived = (archivedEmails) => {
    if (!showArchived) return <></>;

    return <GrowlTable columns={columns} items={archivedEmails} tableName={`${event.slug}-emails-archived`} />;
  };

  const renderEmails = () => {
    if (!fetched()) {
      return <Loading />;
    }

    const directEmails = emails.filter((e) => e.email_type === "broadcast");
    return (
      <div>
        <div className="py-2">
          <GrowlTable
            columns={columns}
            items={directEmails.filter((e) => e.archived === false)}
            tableName={`${event.slug}-emails`}
          />
        </div>
        {renderTransactional()}
        <div className="mt-8 py-2">
          <UiToggle
            flag={showArchived}
            label="Archived"
            toggleFunc={() => {
              setShowArchived(!showArchived);
            }}
          />
          {renderArchived(directEmails.filter((e) => e.archived === true))}
        </div>
      </div>
    );
  };

  const renderNewEmailButton = () => {
    if (editEnabled()) {
      return renderCreateButton("Create New Email", goNew);
    }
  };

  return (
    <div>
      <PageHeader text="Emails" />
      {renderNewEmailButton()}
      {renderEmails()}
    </div>
  );
};

export default CommunicationsEmails;
