import React from "react";

const GraphTotal = props => {
  const { color, total } = props;

  return (
    <div className="absolute right-12 top-0 text-center">
      <div className="text-2xl font-bold" style={{ color: color }}>
        {total}
      </div>
      <div className="text-xs">Total</div>
    </div>
  );
};

export default GraphTotal;
