import React, { useContext, useEffect, useState } from "react";

import { Stack } from "@mui/material";
import axios from "axios";
import { Field, Formik, Form } from "formik";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import { alertError, alertHttpError, alertSuccess } from "@shared/Alerts";
import RequiredAsterisk from "@shared/forms/RequiredAsterisk";
import {
  renderCancelButton,
  renderHtmlEditorField,
  renderSelectField,
  renderSubmitButton,
  renderTextField,
  renderButton
} from "@shared/FormUtils";
import Iframe from "@shared/Iframe";
import { MuiTextField } from "@shared/muiformik/FieldConversions";

import CommunicationsBlankEmailTemplate from "./CommunicationsBlankEmailTemplate";

const CommunicationsEmailTemplateForm = ({
  callbackFailure = () => {},
  callbackSuccess = () => {},
  cancel = () => {},
  template = CommunicationsBlankEmailTemplate
}) => {
  const { apiRoot } = useContext(EventContext).values;
  const [config, setConfig] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const result = await axios(urljoin(apiRoot, "/communications/settings"));
        setConfig(result.data.config);
      } catch (error) {
        alertHttpError(error);
      }
    };

    const fetchTags = async () => {
      try {
        const result = await axios(urljoin(apiRoot, "template_tags"));
        setTags(result.data.tags);
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchTags();
    fetchConfig();
  }, [apiRoot]);

  useEffect(() => {
    if (template && template.id) {
      setSelectedTags(template.tags);
    }
  }, [template]);

  const isEdit = () => {
    return template && template.id;
  };

  const formConfig = (() => {
    if (isEdit()) {
      return {
        alert: "updated",
        formId: "sg-mgmt-form-email-edit",
        formUrl: urljoin(apiRoot, "/communications/email_templates", `/${template.id}`),
        method: "PATCH",
        saveButton: "Save",
        title: template.name
      };
    }
    return {
      alert: "added",
      formId: "sg-mgmt-form-email-add",
      formUrl: urljoin(apiRoot, "/communications/email_templates"),
      method: "POST",
      saveButton: "Save",
      title: "Create New Email Template"
    };
  })();

  const addTag = (gid) => {
    // ignore duplicate tag
    if (selectedTags.map((t) => t.gid).includes(gid)) {
      return;
    }

    // ignore missing tag
    if (!gid) {
      return;
    }

    const tag = tags.find((t) => t.gid === gid);
    setSelectedTags([tag].concat(selectedTags));
  };

  const removeTag = (gid) => {
    setSelectedTags((prevTags) => prevTags.filter((t) => t.gid !== gid));
  };

  const handleAddTagForm = (value) => {
    addTag(value);
  };

  const renderMessageTypeSelect = (formatClasses = []) => {
    const options = [
      { label: "Broadcast", value: "broadcast" },
      { label: "Transactional", value: "transactional" }
    ];
    return renderSelectField("Template Type", "template[message_type]", options, formatClasses, [], true);
  };

  const renderMessageId = (messageType, formatClasses = []) => {
    if (messageType === "transactional") {
      return renderTextField("Template ID (lowercase and hyphens only)", "template[template_id]", formatClasses);
    }
    return <></>;
  };

  const renderFromUsernameField = (setFieldValue) => (
    <div className="sg-mgmt-form-input-container relative">
      <label>
        Default From Address
        <RequiredAsterisk />
      </label>
      <Field
        size="small"
        component={MuiTextField}
        className="sg-mgmt-form-input"
        onChange={(e) => {
          e.preventDefault();
          const { value } = e.target;
          const regex = /^[A-Za-z0-9._%+-]{0,}$/;
          if (regex.test(value.toString())) {
            setFieldValue("template[default_from_username]", value);
          }
        }}
        type="text"
        name="template[default_from_username]"
        autoComplete="off"
      />
      <div className="absolute bottom-2 right-1">@{`${config.email_domain}`}</div>
    </div>
  );

  const renderPreview = (html) => {
    // regex from jQuery for removing script tags
    // ref: https://github.com/jquery/jquery/blob/1.7.2/src/ajax.js#L14
    const noscript = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;
    const sanitizedHtml = html.replace(noscript, "");

    return (
      <div>
        <h2>Preview</h2>
        <div className="mt-2">
          <Iframe scrolling="no" content={sanitizedHtml} />
        </div>
      </div>
    );
  };

  const formInitialValues = () => {
    if (isEdit()) {
      return {
        name: template.name,
        template_id: template.template_id,
        message_type: template.message_type,
        html: template.html,
        default_subject: template.default_subject,
        default_from_name: template.default_from_name,
        default_from_username: template.default_from_username,
        default_reply_address: template.default_reply_address
      };
    }
    return CommunicationsBlankEmailTemplate;
  };

  const renderSelectedTags = () => {
    return (
      <div className="mt-2 flex">
        {selectedTags.map((tag) => (
          <div className="mr-2 flex-initial bg-sg-orange bg-opacity-70 px-2 py-1 text-white" key={`tag-${tag.gid}`}>
            {tag.name}
            <div
              className="ml-1 inline-block cursor-pointer"
              onClick={() => {
                removeTag(tag.gid);
              }}
            >
              &times;
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderTagAddButton = (values) => {
    const tag_add = values.tag_add;
    const selectedTag = tag_add ? tag_add : false;
    const disabled = !selectedTag || selectedTag === "Select option";

    return renderButton("Add", () => handleAddTagForm(tag_add), {
      disabled: disabled
    });
  };

  const renderTagSelect = (values) => {
    const filteredTags = tags.filter((t) => !selectedTags.map((tag) => tag.gid).includes(t.gid));
    const options = filteredTags.map((opt) => ({
      label: opt.name,
      value: opt.gid
    }));
    return (
      <Stack direction="row" spacing={2}>
        {renderSelectField("", "tag_add", options, [], [])}
        {renderTagAddButton(values)}
      </Stack>
    );
  };

  const renderTags = (values) => {
    return (
      <div className="w-1/2 py-4">
        {renderTagSelect(values)}
        {renderSelectedTags()}
      </div>
    );
  };

  const renderForm = () => (
    <Formik
      initialValues={{
        template: formInitialValues()
      }}
      onSubmit={(values, { setSubmitting }) => {
        const token = document.querySelector("[name=csrf-token]").content;
        axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

        // Put tags into array
        const tagGids = selectedTags.map((t) => t.gid);
        values.template.tags = tagGids;

        axios({
          url: formConfig.formUrl,
          method: formConfig.method,
          data: values
        })
          .then((response) => {
            if (response.data.error === null) {
              callbackSuccess(response);
              alertSuccess("Email template saved");
              setSubmitting(false);
            } else {
              callbackFailure(response);
              alertError(`Unable to save email template: ${response.data.error}`);
              setSubmitting(false);
            }
          })
          .catch((error) => {
            alertHttpError(error);
          });
      }}
    >
      {({ values, isSubmitting, setFieldValue }) => (
        <Form className="sg-mgmt-form" id={formConfig.formId}>
          <div className="sg-mgmt-form-container">
            <div className="sg-mgmt-form-section">
              <div className="w-1/2">{renderTextField("Template Name", "template[name]", [], false, true)}</div>
              <div className="w-1/2">{renderMessageTypeSelect()}</div>
              {renderMessageId(values.template.message_type)}
              {renderTextField("Default Subject Line", "template[default_subject]", [], false, true)}
              <div className="flex">
                <div className="mr-4 w-1/3">
                  {renderTextField("Default From Name", "template[default_from_name]", [], false, true)}
                </div>
                <div className="mr-4 w-1/3">{renderFromUsernameField(setFieldValue)}</div>
                <div className="w-1/3">
                  {renderTextField("Default Reply-To Address", "template[default_reply_address]", [], false, true)}
                </div>
              </div>
              {renderHtmlEditorField("HTML", "template[html]", [], true)}
            </div>
          </div>
          <div className="sg-mgmt-form-section">
            <h2>Tags</h2>
            {renderTags(values)}
          </div>
          <Stack direction="row" spacing={2}>
            {renderSubmitButton(formConfig.saveButton, isSubmitting)}
            {renderCancelButton("Cancel", cancel)}
          </Stack>
          <div className="mt-5">{renderPreview(values.template.html)}</div>
        </Form>
      )}
    </Formik>
  );

  return (
    <div>
      <h1>{formConfig.title}</h1>
      {renderForm()}
    </div>
  );
};

export default CommunicationsEmailTemplateForm;
