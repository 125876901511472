import React, { useId, useState } from "react";

import Stack from "@mui/material/Stack";

import InputSlugGenerator from "@shared/forms/InputSlugGenerator";
import { renderRegexField, renderTextField } from "@shared/FormUtils";

const MetadataFieldFormOptions = ({
  field,
  formikProps
}) => {
  const id = useId();
  const blankField = { slug: "", label: "" };
  const defaultOptions = field ? field.options.concat([blankField]) : [blankField];
  const [options, setOptions] = useState(defaultOptions);

  const addOption = () => {
    setOptions((prevOptions) => prevOptions.concat([blankField]));
  };

  const renderOptionsField = (opt, idx) => {
    let option = {};
    option = formikProps?.values?.field?.options?.[idx] || opt;

    const slugRegex = new RegExp("^[0-9A-Za-z_-]{0,32}$");
    return (
      <div className="sg-mgmt-form-row" key={`${id}-${idx}`}>
        <Stack direction="row" spacing={2}>
          <div className="sg-mgmt-form-input-container">
            {renderTextField("Label (question)", `field[options][${idx}][label]`)}
          </div>
          <div className="sg-mgmt-form-input-container">
            {renderRegexField(
              "Identifier (lowercase letters, numbers, hyphens only)",
              `field[options][${idx}][slug]`,
              slugRegex,
              [],
              {
                fieldProperties: {
                  InputProps: {
                    endAdornment: option["slug"] ? (
                      ""
                    ) : (
                      <InputSlugGenerator
                        formikProps={formikProps}
                        name={`field[options][${idx}][slug]`}
                        associatedValue={option["label"]}
                        maxSlugLength={30}
                      />
                    )
                  }
                }
              }
            )}
          </div>
        </Stack>
      </div>
    );
  };

  const renderOptionsFields = () => {
    return (
      <div>
        <p>Enter options for dropdown/checkboxes:</p>
        {options.map((opt, idx) => renderOptionsField(opt, idx))}
        <span className="sg-mgmt-link" onClick={addOption}>
          + Add another option
        </span>
      </div>
    );
  };

  return renderOptionsFields();
};

export default MetadataFieldFormOptions;
