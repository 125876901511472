import React from "react";
import clsx from "clsx";

const Button = ({
  className = "",
  children,
  disabled = false,
  format = "select",
  label = "",
  onClick = () => {},
  submit = false
}) => {

  const baseClasses = "inline-block text-base leading-4 p-3 border-sg-orange border border-solid h-[40px]";
  let buttonStyles = "";

  switch(format) {
    case "select":
      buttonStyles = "bg-sg-orange text-white";
      break;
    case "cancel":
      buttonStyles = "bg-transparent text-sg-orange";
      break;
  }

  return (
    <button
      className={clsx(baseClasses, buttonStyles, className)}
      disabled={disabled}
      onClick={onClick}
      type={submit ? "submit" : "button"}
    >
      {label}
      {children}
    </button>
  );
};


export default Button;
